import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes, Link, Switch } from "react-router-dom";
import Home from "./pages/Home";
import HomeEnglish from "./pages/HomeEnglish";
import VideoPageMobile from "./comp/english/VideoPageMobile";
import VideoPage from "./comp/VideoPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/challenges" element={<VideoPage />} />
            <Route exact path="/challenge/:id/:name" element={<VideoPageMobile />} />
        <Route exact path="/:id/challengesM" element={<VideoPageMobile />} />
        <Route exact path="/:id" element={<HomeEnglish />} />
    
      </Routes>
    </BrowserRouter>
  );
}

export default App;
