let pages = {
  home: {
    title: " Ikuti Tantangan Finansial Literasi Anda Sekarang",
    button: "Mulai",
    route: "bahasa",
    back:"Kembali",
    container: {
      title: "Tantangan Kami",

      card1: {
        title: "Bunga Besar",
      },
      card2: {
        title: "Menandatangani Pinjaman",
      },
      card3: {
        title: "Penipuan Berkedok Cinta",
      },
    },
    thanks: {
      title: "Terima kasih kepada Artis Suara kami!",
      names: ["Septi Yulianawati", 
        "Sringatin",
        "Clemens"
      ]
    }
  },
  video:{
    home:"rumah",
    more:"Lebih Banyak Tantangan",
    continue:"Melanjutkan",
    tips:"tip",
    good:"Kerja bagus!",
    red:"Bendera merah!",
    challenge:"Tantangan selesai",

    play:"Main lagi",
    back:"Pulang ke rumah",
    next:"Tantangan selanjutnya"
}
};
const data = [
  {
    heading: "Bunga Besar",
    id: 1,
    url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Farah Bahasa 480p.mp4",
    subs: "/img/bahasa/Farah.vtt",
    Des: "V2-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
    continuable: {
      cando: true,
    },
    questions: [
      {
        questionText:
          "TEMUKAN AKTIVITAS YANG BERTANDA BAHAYA<br><br>Pastikan tempat peminjaman uang memiliki legalitas yang sah dan reputasi baik yang dapat dipercaya!<br><br>Sering kali lintah darat memberikan suku bunga yang berlebihan, menjadikan kamu kesulitan dalam membayar pinjaman.<br><br>Silahkan lihat beberapa contoh surat perjanjian hutang berikut ini dan cari tanda-tanda bahayanya!",
        continuable: {
          cando: true,
        },
        correctOption: "",
        answerDes:
          "CSS stands for Cascading Style Sheets. It is a stylesheet language used to describe the presentation of web pages, including elements like colors, fonts, and layout.",
      },
      {
        questionText: "<img  loading='lazy' alt='images' src='/img/activity1.png' width='300' height='400'>",
        options: ["Tanda Positif", "Tanda Bahaya"],
        correctOption: "Tanda Bahaya",
        answerDesCorrect:
          "Suku bunga di atas 48% itu sudah sangat tinggi, dan sejak tahun 2023, sudah menjadi ilegal di Hong Kong. Bila pemberi pinjaman mencantumkan suku bunga yang hanya sedikit lebih rendah dari 48%, itu patut dicurigai! Klik Lanjutkan untuk melihat surat perjanjian hutang lainnya.",
        answerDesWrong:
          "Suku bunga di atas 48% itu sudah sangat tinggi, dan sejak tahun 2023, sudah menjadi ilegal di Hong Kong. Bila pemberi pinjaman mencantumkan suku bunga yang hanya sedikit lebih rendah dari 48%, itu patut dicurigai! Klik Lanjutkan untuk melihat surat perjanjian hutang lainnya.",
      },
      {
        questionText: "<img  loading='lazy' alt='images' src='/img/acitivity2.png' width='300 height='400'>",
        options: ["Tanda Positif", "Tanda Bahaya"],
        correctOption: "Tanda Bahaya",
        answerDesCorrect:
          " Di Hong Kong, suku bunga yang mencapai 48% atau lebih adalah ilegal! Waspada akan pemberi pinjaman yang tidak bermoral! Klik Lanjutkan untuk melihat surat perjanjian hutang lainnya.",
        answerDesWrong:
          " Di Hong Kong, suku bunga yang mencapai 48% atau lebih adalah ilegal! Waspada akan pemberi pinjaman yang tidak bermoral! Klik Lanjutkan untuk melihat surat perjanjian hutang lainnya.",
      },
      {
        questionText: "<img  loading='lazy' alt='images' src='/img/activity3.png' width='300' height='400'>",
        options: ["Tanda Positif", "Tanda Bahaya"],
        newhead: {
          cando: true,
          heading: "",
        },
        correctOption: "Tanda Positif",
        answerDesCorrect:
          "Sepertinya pinjaman ini dapat menjadi pilihan yang baik. Pastikan untuk menggunakan penilaian terbaik anda dan pilihlah pinjaman sesuai dengan kemampuan kamu untuk membayarnya. Klik Lanjutkan untuk melihat apa yang akan terjadi selanjutnya.",
        answerDesWrong:
          "Sepertinya pinjaman ini dapat menjadi pilihan yang baik. Pastikan untuk menggunakan penilaian terbaik anda dan pilihlah pinjaman sesuai dengan kemampuan kamu untuk membayarnya. Klik Lanjutkan untuk melihat apa yang akan terjadi selanjutnya.",
      },
      {
        questionText: "Apa yang harus Farah lakukan?",
        options: [
          '"Oh wow! Kedengarannya ini sangat bagus!"',
          '"Um… saya tidak tahu, lagipula saya masih harus membayar pinjaman sebelumnya, saya tidak mau mengambil pinjaman yang baru…"',
        ],
        correctOption:
          '"Um… saya tidak tahu, lagipula saya masih harus membayar pinjaman sebelumnya, saya tidak mau mengambil pinjaman yang baru…"',

        answerDesCorrect:
          "Pemberi pinjaman uang merupakan sebuah bisnis. Apabila mereka menawarkan untuk merubah isi perjanjian hutang kamu atau meminta kamu untuk mengambil pinjaman baru, kamu harus waspada — apalagi jika mereka menawarkan barang secara gratis atau memaksa kamu untuk menyetujui pinjaman yang lebih besar.<br><br>Yang dimaksud dengan re-loan atau pinjaman kembali adalah ketika pinjaman baru melunasi pinjaman kamu sebelumnya dan kamu dapat meminjam lebih banyak uang. Kadang kala hal ini dapat berguna, namun seringkali hal ini dapat menjadikan suku bunga hutang kamu meningkat yang pada akhirnya mempersulit kamu mengontrol pembayaran hutang yang terlalu tinggi.<br><br>Sebelum kamu menyetujui apapun, beri waktu untuk mempertimbangkan apakah re-loan merupakan keputusan yang bijak, carilah pertolongan dari NGO atau ahli keuangan untuk membantu kamu membuat keputusan.",

        answerDesWrong:
          "Pemberi pinjaman uang merupakan sebuah bisnis. Apabila mereka menawarkan untuk merubah isi perjanjian hutang kamu atau meminta kamu untuk mengambil pinjaman baru, kamu harus waspada — apalagi jika mereka menawarkan barang secara gratis atau memaksa kamu untuk menyetujui pinjaman yang lebih besar.<br><br>Yang dimaksud dengan re-loan atau pinjaman kembali adalah ketika pinjaman baru melunasi pinjaman kamu sebelumnya dan kamu dapat meminjam lebih banyak uang. Kadang kala hal ini dapat berguna, namun seringkali hal ini dapat menjadikan suku bunga hutang kamu meningkat yang pada akhirnya mempersulit kamu mengontrol pembayaran hutang yang terlalu tinggi.<br><br>Sebelum kamu menyetujui apapun, beri waktu untuk mempertimbangkan apakah re-loan merupakan keputusan yang bijak, carilah pertolongan dari NGO atau ahli keuangan untuk membantu kamu membuat keputusan.",
      },
    ],
    tips: "Apabila kamu memutuskan untuk mengambil pinjaman, pastikan kamu memilih tempat peminjaman uang yang legal, terpercaya dan memiliki reputasi yang baik. Mereka seharusnya memandu kamu dalam memahami semua detail tentang pinjaman tersebut. Jika kamu merasa tidak nyaman atau merasa dipaksa, kamu dapat meninggalkan tempat itu!<br /><br />Di Hong Kong, sejak Januari 2023, merupakan hal yang ilegal apabila suku bunga tahunan lebih dari 48%, besar kemungkinan agensi terlibat kegiatan yang ilegal.<br /><br />Ingatlah: Pastikan untuk menyimpan semua resi, kwitansi, foto dan dokumen terkait dengan agensi atau tempat peminjaman uang. Jangan membuang apapun, karena itu dapat membantu kamu nantinya!<br /><br />Apabila kamu sering diganggu atau merasa dalam bahaya, segera hubungi polisi di daerah kamu.",
    times: [72, 73, 74, 75, 115],
  },
  {
    heading: "Menandatangani Pinjaman",
    id: 2,
    url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Josie Bahasa 480p.mp4",
    subs: "/img/bahasa/Josie.vtt",
    Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
    questions: [
      {
        questionText: "Apa yang harus Josie lakukan?",
        options: [
          '"Apa kamu yakin mengambil pinjaman itu ide yang bagus? Apa mereka dapat dipercaya?"',
          '"Tentu saja, kamu bisa mengambil pinjaman!"',
        ],
        correctOption:
          '"Apa kamu yakin mengambil pinjaman itu ide yang bagus? Apa mereka dapat dipercaya?"',
        answerDesCorrect:
          " Sangat penting untuk selalu waspada dan mencari tempat peminjaman uang yang legal dan memiliki reputasi yang baik jika kamu memutuskan untuk meminjam uang. Pastikan bahwa kamu mampu untuk membayar pinjaman tersebut.<br /><br />Klik Lanjutkan untuk mencari tahu apa yang akan terjadi jika Josie setuju…",
        answerDesWrong:
          " Sangat penting untuk selalu waspada dan mencari tempat peminjaman uang yang legal dan memiliki reputasi yang baik jika kamu memutuskan untuk meminjam uang. Pastikan bahwa kamu mampu untuk membayar pinjaman tersebut.<br /><br />Klik Lanjutkan untuk mencari tahu apa yang akan terjadi jika Josie setuju…",
      },
      {
        questionText: "Apa yang harus Josie lakukan?",
        options: [
          '"Saya tidak yakin. Jujur ya, saya merasa tidak nyaman untuk mencantumkan nama saya. Mungkin kita bisa mencari cara lain agar kamu bisa mendapatkan uang itu."',
          '"Baiklah, selama itu bisa membantu! Kamu boleh mencantumkan nama saya sebagai referensinya!"',
        ],
        correctOption:
          '"Saya tidak yakin. Jujur ya, saya merasa tidak nyaman untuk mencantumkan nama saya. Mungkin kita bisa mencari cara lain agar kamu bisa mendapatkan uang itu."',
        answerDesCorrect:
          "Sangat Bagus! Mencantumkan nama kamu sebagai referensi, penjamin atau rekan peminjam sangatlah beresiko tinggi! Karena, ketika teman kamu tidak mampu membayar, bisa jadi kamu yang harus bertanggung jawab membayar pinjaman tersebut. Hal ini dapat menempatkan kamu dalam keadaan yang sangat berbahaya.<br /><br />Apabila kamu setuju untuk menjadi referensi, bisa jadi kamu juga setuju untuk bertanggung jawab atas semua pinjaman tersebut.<br /><br />Klik Lanjutkan untuk mencari tahu apa yang terjadi pada Josie bila dia setuju untuk menjadi referensi…",
        answerDesWrong:
          " Mencantumkan nama kamu sebagai referensi, penjamin atau rekan peminjam sangatlah beresiko tinggi! Karena, ketika teman kamu tidak mampu membayar, bisa jadi kamu yang harus bertanggung jawab membayar pinjaman tersebut. Hal ini dapat menempatkan kamu dalam keadaan yang sangat berbahaya.<br /><br />Apabila kamu setuju untuk menjadi referensi, bisa jadi kamu setuju untuk bertanggung jawab atas semua semua pinjaman tersebut.<br /><br />Klik Lanjutkan untuk mencari tahu apa yang terjadi pada Josie bila dia setuju untuk menjadi referensi…",
      },
    ],
    tips: "Hindari menjadikan diri kamu sebagai referensi, rekan peminjam atau penjamin untuk pinjaman hutang orang lain. Sebagai penjamin atau rekan peminjam, secara hukum kamu harus bertanggung jawab atas pinjaman tersebut bilamana si peminjam menghilang. Sebagai referensi, ada kemungkinan kamu tidak perlu bertanggung jawab, tapi lintah darat dapat saja menagihnya kepada kamu!<br /><br />Hal ini sangat beresiko tinggi dan membahayakan diri kamu! Pastikan untuk tetap berkomunikasi dengan peminjam utama. Ingatlah! Ketika kamu setuju untuk menjadi referensi, bisa jadi kamu juga setuju untuk bertanggung jawab atas seluruh pinjaman tersebut!<br /><br />Bilamana kamu memutuskan untuk mengambil pinjaman, pastikan untuk memilih tempat peminjaman uang yang legal dan terpercaya. Mereka seharusnya memandu anda mengenai semua detail pinjaman anda dan memastikan anda membaca dan mengerti setiap dokumen yang anda tandatangani! Jika kamu merasa tidak nyaman atau merasa dipaksa, kamu dapat meninggalkan tempat itu!<br /><br />Ingatlah: Pastikan untuk menyimpan semua resi, kwitansi, foto dan dokumen terkait dengan agensi atau tempat peminjaman uang. Jangan membuang apapun, karena itu dapat membantu kamu nantinya!<br /><br />Apabila kamu sering diganggu atau merasa dalam bahaya, segera hubungi polisi di daerah kamu.",
    times: [92, 122],
  },
  {
    heading: "Penipuan Berkedok Cinta",
    id: 3,
    url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Nina Bahasa 480p.mp4",
    subs: "/img/bahasa/Nina.vtt",
    Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
    questions: [
      {
        questionText: "Apa yang harus Nina lakukan?",
        options: [
          '"Hmmm…kita belum pernah bertemu sebelumnya. Saya masih belum yakin…"',
          '"Aku sudah tidak sabar ingin bertemu kamu!"',
        ],
        correctOption:
          '"Hmmm…kita belum pernah bertemu sebelumnya. Saya masih belum yakin…"',
        answerDesCorrect:
          "Orang-orang di dunia maya mungkin tidaklah seperti yang mereka katakan! Penipu dapat saja menggunakan teknologi AI dan Deep Fake untuk merubah suara dan tampilan fisik mereka secara online. Berhati-hatilah ketika anda mengenal teman baru baik itu di dunia maya maupun secara langsung.<br /><br />Penipu akan memberikan berbagai alasan untuk tidak melakukan panggilan telepon dan selalu menghindar untuk bertemu secara langsung. Mereka mungkin juga akan menjanjikan hadiah untuk membangun kepercayaan kamu. Bila kamu tidak mengenal orang tersebut, akan sangat baik bila kamu selalu waspada.<br /><br />Klik Lanjutkan untuk melihat apa yang akan terjadi bila Nina masih terus mempercayai John.",
        answerDesWrong:
          "Orang-orang di dunia maya mungkin tidaklah seperti yang mereka katakan! Penipu dapat saja menggunakan teknologi AI dan Deep Fake untuk merubah suara dan tampilan fisik mereka secara online. Berhati-hatilah ketika anda mengenal teman baru baik itu di dunia maya maupun secara langsung.<br /><br />Penipu akan memberikan berbagai alasan untuk tidak melakukan panggilan telepon dan selalu menghindar untuk bertemu secara langsung. Mereka mungkin juga akan menjanjikan hadiah untuk membangun kepercayaan kamu. Bila kamu tidak mengenal orang tersebut, akan sangat baik bila kamu selalu waspada.<br /><br />Klik Lanjutkan untuk melihat kelanjutan ceritanya.",
      },
      {
        questionText: "Apa yang harus Nina lakukan?",
        options: [
          '"Entahlah. Aku  merasa tidak nyaman"',
          '"Jangan khawatir. Aku akan segera mengirimkan uangnya!"',
        ],
        correctOption: '"Entahlah. Aku  merasa tidak nyaman"',
        answerDesCorrect:
          " Penipu akan menggunakan berbagai trik dan manipulasi untuk meminta kamu mengirim uang atau melakukan tindakan illegal. Akan sangat baik jika kita selalu waspada agar tidak menjadi korbannya!",
        answerDesWrong:
          " Penipu akan menggunakan berbagai trik dan manipulasi untuk meminta kamu mengirim uang atau melakukan tindakan illegal. Akan sangat baik jika kita selalu waspada agar tidak menjadi korbannya!",
      },
    ],
    tips: "Penipu Cinta dapat saja memperdayai kamu dengan berlagak seperti orang yang kaya dan berkuasa. Mereka juga sangat ahli dalam membangun kepercayaan dengan berpura-pura peduli pada kamu dan dengan segera membina hubungan denganmu. Mereka akan menggunakan berbagai manipulasi untuk menipu kamu.<br /><br />Penipu dapat menggunakan teknologi AI dan Deep Fake untuk merubah suara dan tampilan fisik mereka! Berhati-hatilah saat kamu berkenalan dengan orang baru baik itu dunia maya atau secara langsung.<br /><br />Jika anda berada di Hong Kong dan memiliki keraguan atau curiga akan penipuan, anda dapat menghubungi 18222 untuk menjangkau Saluran Bantuan Anti Penipuan dari Polisi Hong Kong bagian Pusat Koordinasi Anti Penipuan.<br /><br />Bila kamu merasa diganggu atau dalam bahaya, segera hubungi polisi setempat.",
    times: [113, 171],
  },
  // Add more videos here
];
module.exports.constantsBahsapages = { pages, data };
