import React, { useEffect, useState } from "react";
import Hero from "../comp/Hero";
import HeroMobile from "../comp/HeroMobile";
import Navbar from "../comp/Navbar";
import NavbarMobile from "../comp/NavbarMobile";
import OurChallenges from "../comp/OurChallenges";
import OurchallengesMobile from "../comp/OurchallengesMobile";
import Footer from "../comp/Footer";
import FooterMobile from "../comp/FooterMobile";
import Api from "../axios.js";

function Home() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 821);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    Api.get("/registerCookie", { withCredentials: true }).then((res) => {
      console.log(res);
      // setCookie("session_id", res.data.session_id, 1)
    });
  }, []);

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      console.log(position.coords.latitude);
      console.log(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
    Api.post("/analytics/sendTrackingData", {
      lat: lat,
      lng: lng,
      country: "",
      video_name: "",
      eventType: "CLICK",
      ipv4Address: "",
      ipv6Address: "",
      progress_percentage: "",
      endpoint: "/",
    }).then((res) => {
      console.log(res);
    });
  }, [lat, lng]);

  return (
    <div>
      {isMobileView ? (
        <div>
          <NavbarMobile />
          <HeroMobile />
          <OurchallengesMobile lang={"english"} />
          <FooterMobile />
        </div>
      ) : (
        <div>
          <Navbar />
          <Hero />
          <OurChallenges lang={"english"} />
          <Footer />
        </div>
      )}

      {/* <OurChallenges/>
            <Footer/> */}
    </div>
  );
}

export default Home;
