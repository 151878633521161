import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function Hero({ pageData, lang,pageData2 }) {
  const handleLanguage = (e) => {
    console.log(e.target.value);
    if (e.target.value === "English") {
      window.location.href = "/english";
    } else if (e.target.value === "Bahasa") {
      window.location.href = "/bahasa";
    }
    else if (e.target.value === "Tagalog") {
      window.location.href = "/tagalog";
    }
    

  };
  const navigate = useNavigate();
  const selectVid = (id,name) => {
    
    navigate((`/challenge/${lang}/${name}`).replaceAll(" ","+").toLowerCase(), { state: { vid: id } });
  };

  return (
    <>
      <div
        className="hero-image"
        style={{
          backgroundImage: `url('https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/images/IMG_2993.avif')`,
          display: "block",
          width: "100%",
          height: "80vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          zIndex: "1",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "80vh",

            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            // justifyContent:"center",

            position: "relative",
            zIndex: "2",
          }}
        >
          <div
            className="hero-text"
            style={{
              textAlign: "center",
              marginLeft: "50px",

              // color: "white",
              textAlign: "left",
              fontFamily: "monospace",
              fontWeight: "bold",
              maxWidth: 500,
              fontSize: "50px",
              // color: "rgb(3, 4, 94)",
              color: "white",
              opacity: "1",
            }}
          >
            <p>
              {pageData?.title ||
                " Migrant Worker Financial Literacy Challenge"}
            </p>
            {/* <a href="/challengesM" style={{ textDecoration: "none" }}> */}
            {!pageData ? (
              <button
                style={{
                  backgroundColor: "rgba(174, 0, 87, 1)",
                  color: "white",
                  padding: "10px 10px 10px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  textDecoration: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                  borderRadius: "12px",
                  borderRadius: "25px",
                  border: "none",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  minWidth: "240px",
                  marginTop: "20px",
                }}
              >
                {/* Choose a language */}
                <select
                  style={{
                    scale: "1.3",
                    backgroundColor: "rgba(174, 0, 87, 0.9)",
                    border: "none",
                    color: "white",
                  }}
                  onChange={(e) => handleLanguage(e)}
                >
                  <option value="null">Choose a language</option>
                  <option value="English">English</option>
                  <option value="Bahasa">Bahasa Indonesia</option>
                  <option value="Tagalog">Tagalog</option>
                  {/* <option value="Malay">Malay</option>
						<option value="Filipino">Filipino</option> */}
                </select>
                {/* <BsArrowRight
                        style={{
                            marginLeft: "10px",
                            fontSize: "15px",
                            position: "relative",
                        }}
                    /> */}
              </button>
            ) : (
              <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginTop:10}}>
                     <button
                  style={{
                    backgroundColor: "#AE0057",
                    color: "white",
                    padding: "10px 10px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "20px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    marginRight:10,
                    borderRadius: "25px",
                    border: "none",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    width: "150px",
                    // marginTop: "20px",
                  }}
                  onClick={() => {
                    navigate("/")
                   }}
                >
                   <BsArrowLeft
                    style={{
                      marginRight: "auto",
                      marginLeft:"auto",
                      fontSize: "15px",
                      position: "relative",
                    }}
                  />
                  {pageData?.back||"Back"}
                 
                </button>
                {" "}
                <button
                  style={{
                    backgroundColor: "#AE0057",
                    color: "white",
                    padding: "10px 10px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "20px",
                    cursor: "pointer",
                    borderRadius: "12px",
                    borderRadius: "25px",
                    border: "none",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                    width: "150px",
                    // marginTop: "20px",
                  }}
                   onClick={() => {
              selectVid(1, pageData2?.card1?.title || "Excessive Interest");
            }}
                >
                  {pageData?.button}
                  <BsArrowRight
                    style={{
                      marginLeft: "10px",
                      fontSize: "15px",
                      position: "relative",
                    }}
                  />
                </button>
              </div>
            )}
            {/* </a> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
