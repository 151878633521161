import React, { useState, useEffect } from "react";
import { Route, useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function HeroMobile({ pageData, lang }) {
  

  const navigate = useNavigate();

  const selectVid = (id,name) => {
    
    navigate((`/challenge/${lang}/${name}`).replaceAll(" ","+").toLowerCase(), { state: { vid: id } });
  };

  return (
    <>
      <div
        className="hero-image"
        style={{
            backgroundImage: `url('https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/images/IMG_2993.avif')`,
          display: "block",
          width: "100%",
          height: "80vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          zIndex: "1",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "80vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            zIndex: "2",
          }}
        >
          <div
            className="hero-text"
            style={{
              textAlign: "center",
              position: "absolute",
              top: "65%",
              left: "35%",
              transform: "translate(-50%, -50%)",
              // color: "white",
              textAlign: "left",
              fontFamily: "monospace",
              fontWeight: "bold",
              fontSize: "24px",
              // color: "rgb(3, 4, 94)",
              color: "white",
              padding: "10px",
              opacity: "1",
            }}
          >
            <p>
              {pageData?.title ||
                " Migrant Worker Financial Literacy Challenge"}
            </p>

            <div>
              {" "}
              <button
                style={{
                  backgroundColor: "#AE0057",
                  color: "white",
                  padding: "10px 10px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  marginRight: 10,
                  fontSize: "20px",
                  cursor: "pointer",
                  borderRadius: "12px",
                  borderRadius: "25px",
                  border: "none",
                  fontFamily: "sans-serif",
                  // fontWeight: "bold",
                  // width: "120px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <BsArrowLeft
                  style={{
                    marginRight: "10px",
                    fontSize: "15px",
                    position: "relative",
                  }}
                />
                {pageData?.back || "Back"}
              </button>
              <button
                style={{
                  backgroundColor: "#AE0057",
                  color: "white",
                  padding: "10px 10px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "20px",
                  cursor: "pointer",
                  borderRadius: "12px",
                  borderRadius: "25px",
                  border: "none",
                  fontFamily: "sans-serif",
                  // fontWeight: "bold",
                  // width: "100px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  selectVid(1);
                }}
              >
                {pageData?.button}
                <BsArrowRight
                  style={{
                    marginLeft: "10px",
                    fontSize: "15px",
                    position: "relative",
                  }}
                />
              </button>
            </div>
            {/* </a> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroMobile;
