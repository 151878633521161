import React from 'react'
import { FaPlayCircle } from "react-icons/fa";
import "./ourchallengesMobile.css"
import { useLocation, useNavigate } from "react-router-dom";



export default function OurChallenges({ pageData, lang }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectVid = (id,name) => {
    
    navigate((`/challenge/${lang}/${name}`).replaceAll(" ","+").toLowerCase(), { state: { vid: id } });
  };
  return (



    <div className='challengem'>
      <h2 className='challenge-headingm'>Our Challenges</h2>

      <div className='Challenge-video-continerm'>

      


        <div className='Challenge-sub-videom'>
        
          <div style={{cursor:"pointer"}}  className='cardm'
            onClick={() => {
              selectVid(1,pageData?.card1?.title||"Excessive Interest");
            }}
          >
            <img  loading='lazy' alt='images' className='sub-img'   src="https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/images/1.avif"></img>
            <div className='video-timem'>02:00</div>
            <div className='card-bottomm'>
              <p className='card-headingm'>Excessive Interest</p>
              <FaPlayCircle size={35} color='#03045e'
                style={{ paddingTop: "6px" }}

              />

            </div>

          </div>
          <div style={{cursor:"pointer"}}  className='cardm'
            onClick={() => {
              selectVid(2,pageData?.card2?.title||"Signing for Loans");
            }}
          >
            <img  loading='lazy' alt='images' className='sub-imgm'   src="https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/images/2.avif"></img>
            <div className='video-timem '>02:22</div>
            <div className='card-bottomm'>
              <p className='card-headingm'>Signing for Loans</p>
              <FaPlayCircle size={35} color='#03045e'
                style={{ paddingTop: "6px" }}

              />

            </div>

          </div>
          <div style={{cursor:"pointer"}}  className='cardm'
            onClick={() => {
              selectVid(3,pageData?.card3?.title||"Love Scams");
            }}
          >
            <img  loading='lazy' alt='images' className='sub-imgm' src="https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/images/3.avif"></img>
            <div className='video-timem'>03:00</div>
            <div className='card-bottomm'>
              <p className='card-headingm'>Love Scams</p>
              <FaPlayCircle size={35} color='#03045e'
                style={{ paddingTop: "6px" }}

              />

            </div>

          </div>



        </div>
      </div>

    </div>
  )
}
