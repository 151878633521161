import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import "./video.css"
import data from "./data";



import { AiFillCaretRight, AiOutlinePause, AiOutlineExpand, AiOutlineCompress } from "react-icons/ai";
import { FaBars, FaTimes, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import screenfull from 'screenfull';

function Video(props) {
  const [playing, setPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestions, setShowQuestions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);

  const [videoDuration, setVideoDuration] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [answerDes, setAnswerDes] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [isBarAppere, setIsBarAppere] = useState(true);

  const [captions_arr, setCaptions] = useState([]);

  let setIsFullScreen = props.setIsFullScreen;
  let isFullScreen = props.isFullScreen;
  let selectedVideo = props.selectedVideo;
  let setSelectedVideo = props.setSelectedVideo;

  const playerRef = useRef(null);

  const handleVideoClick = (videoId) => {
    setSelectedVideo(videoId);
  };



  const togglePlay = () => {
    setPlaying(prevPlaying => !prevPlaying);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(prevFullScreen => !prevFullScreen);
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      }
      else {
        screenfull.request();

      }
    }
  };

  const handleSpeedChange = newSpeed => {
    setPlaybackRate(newSpeed);
  };

  useEffect(() => {
    setCurrentQuestionIndex(0);
    setShowQuestions(false);
  }, [props.url]);

  const handleQuestionSubmit = (e) => {
    e.preventDefault();

    if (selectedOption) {
      const correctAnswer = props.questions[currentQuestionIndex].correctOption;
      const isCorrect = selectedOption === correctAnswer;

      setIsAnswerCorrect(isCorrect);
      setIsAnswerSubmitted(true);
      setShowAnswer(true);

      if (isCorrect) {

        // setPlaying(true);
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setShowQuestions(false);
        setShowAnswer(true); // Hide answer message for the next question
        setAnswerDes(true);

      } else {
        setShowQuestions(false);
        // setPlaying(true);
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setShowAnswer(true); // Show answer message for incorrect answer
        setAnswerDes(true);
      }

      setSelectedOption(null);
    }
  };

  const ansSkipBtn = () => {
    setAnswerDes(false);
    setPlaying(true);

  };


  // const handleSubtitles = () => {


  // }








  const handleProgress = (state) => {
    const currentTime = state.playedSeconds;

    if (currentTime >= props.times[currentQuestionIndex]) {
      setShowQuestions(true);
      setPlaying(false);

      const prevProgress = videoProgressData[props.id]
        ? videoProgressData[props.id].progress
        : 0;

      if (currentTime > prevProgress) {
        setVideoProgressData((prevProgressData) => ({
          ...prevProgressData,
          [props.id]: {
            id: props.id,
            progress: currentTime,
          },
        }));

        localStorage.setItem(
          'userVideoProgress',
          JSON.stringify(videoProgressData)
        );
      }
    } else {
      setVideoProgressData((prevProgressData) => ({
        ...prevProgressData,
        [props.id]: {
          id: props.id,
          progress: currentTime,
        },
      }));

      localStorage.setItem(
        'userVideoProgress',
        JSON.stringify(videoProgressData)
      );
    }
  };

  useEffect(() => {

    const storedProgressData = localStorage.getItem('userVideoProgress');
    if (storedProgressData) {
      const parsedProgressData = JSON.parse(storedProgressData);
      setVideoProgressData(parsedProgressData);
    }
  }, []);



  function clearVideoProgressData() {
    localStorage.removeItem('userVideoProgress');
    setVideoProgressData({});
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // const toggleTimeLineVisibility = (e) => {


  //   if(isBarAppere){
  //     setIsBarAppere(false)

  //   }else{
  //     setIsBarAppere(true)

  //   }




  // }

  // useEffect(() => {

  //   setIsBarAppere(true)





  //     if (isBarAppere) {
  //       setTimeout(() => {
  //         setIsBarAppere(false)
  //       }, 3000);



  //   }
  // }, [isBarAppere]);

  // const handleBar = () => {
  //   setIsBarAppere(!isBarAppere)
  //   console.log("worked")
  //   if(isBarAppere===false){
  //     setIsBarAppere(true)
  //   }

  // }

  const toggleTimelineVisibility = () => {


    setIsBarAppere(true);
    setTimeout(() => {
      setIsBarAppere(false);

    }, 4000);

  };







  return (
    <div >
      <div className='player-wrapper'>
        <h2 className='heading'>{props.heading}</h2>
        <div onClick={toggleTimelineVisibility} className={`custom-video-player ${isFullScreen ? 'fullscreen' : ''}`}>
          <ReactPlayer
            ref={playerRef}
            url={props.url}
            controls={false}


            onProgress={(progress) => {
              setCurrentTime(progress.playedSeconds);
              handleProgress(progress); // Call your handleProgress function here
            }}
            // onMouseMove={() =>{setIsBarAppere(true)}}
            // onMouseLeave={() =>{setIsBarAppere(false)}}




            playing={playing}
            className='video'
            playbackRate={playbackRate}
            width={isFullScreen ? '100vw' : '80vw'}
            height={isFullScreen ? '100vh' : '70vh'}
            onDuration={(duration) => setVideoDuration(duration)}
            config={{
              file: {
                tracks: [
                  {
                    kind: 'subtitles',
                    src: "/img/Grace.vtt",
                    srcLang: 'en',
                    default: true,
                  },
                ],
              },
            }}


          />


        </div>
        {
          isBarAppere ? (<div className={`custom-controls ${isFullScreen ? 'fullscreen' : ''}`}>
            <div className='btn-time' >

              <p onClick={togglePlay} className='play'>
                {playing ? <AiOutlinePause /> : <AiFillCaretRight />}
              </p>
              <p>  {formatTime(currentTime)} : {formatDuration(videoDuration)}</p>

            </div>
            <input
              type="range"
              min="0"
              max={videoDuration}
              value={currentTime}
              className="slider"
              id="myRange"
              currentTime={currentTime}
              videoDuration={videoDuration}
              readOnly
            />


            <div className='fullscreen-speed'>
              <p onClick={toggleFullScreen} className='fullscreen'>
                {isFullScreen ? <AiOutlineCompress id='fs' /> : <AiOutlineExpand />}
              </p>
              <select className='speed' onChange={e => handleSpeedChange(parseFloat(e.target.value))}>
                <option className='option' value="1">Speed</option>
                <option className='option' value="0.5">0.5x</option>
                <option className='option' value="1">1x</option>
                <option className='option' value="1.5">1.5x</option>
                <option className='option' value="8">2x</option>
              </select>
            </div>

          </div>) : ""

        }





          {showQuestions && (
            <form className={`form-container ${isFullScreen ? 'fullscreen' : ''}`}>
              <p className={`form-Qus ${isFullScreen ? 'fullscreen' : ''}`}>{props.questions[currentQuestionIndex].questionText}</p>
              {props.questions[currentQuestionIndex].options.map((option, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    id={`option${index}`}
                    name="options"
                    value={option}
                    className='styled-radio'
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(option)}
                  />

                  <label className='form-Op' htmlFor={`option${index}`}>{option}</label>
                </div>
              ))}
              <div className='ans-sumbit-btn-con'>

                <button onClick={handleQuestionSubmit} className='form-btn'>Submit</button>
              </div>


            </form>
          )}

     



      </div>

      <button className={`sidebar-toggle-button ${isFullScreen ? 'fullscreen' : ''}`} onClick={() => setShowSidebar(!showSidebar)}>
        {
          !showSidebar ? (<FaAngleDoubleLeft />) : (<FaAngleDoubleRight />)}


      </button>

      <div >
        {
          answerDes ? (<p className={`answer-container ${isFullScreen ? 'fullscreen' : ''}`}>
            {isAnswerCorrect ? (
              <>
                <h3 className='ans-c'>Correct Answer</h3>
                <p>{props.questions[currentQuestionIndex - 1].answerDesCorrect}</p>
                <div className='ans-btn-con'>

<button onClick={ansSkipBtn} className='ansPlayBtn'>Continue</button>
</div>
              </>

            ) : (
              <>
                <h3 className='ans-w'>Wrong Answer</h3>
                <p>{props.questions[currentQuestionIndex - 1].answerDesWrong}</p>
                <div className='ans-btn-con'>

                  <button onClick={ansSkipBtn} className='ansPlayBtn'>Continue</button>
                </div>
              </>
            )}

          </p>) : (<br></br>)
        }


      </div>
      {
        showSidebar ? (<div className="video-list">
          <h2 className="video-list-heading" > Upcoming Challenges</h2>
          {data.map((information, index) => (
            <div
              key={index}
              className={`video-item ${information.id === selectedVideo ? "selected" : ""
                }`}
              onClick={() => handleVideoClick(information.id)}
            >
              {information.heading}
            </div>
          ))}
        </div>) : ""
      }





      <div className={`des-container ${isFullScreen ? 'fullscreen' : ''}`} >

        <h2 className='des-head'><u>Video Progress Data</u></h2>
        {Object.values(videoProgressData).map((video, index) => (
          <p key={index}>Video ID: {video.id}, Progress: {video.progress}</p>
        ))}
        <button onClick={clearVideoProgressData}>Clear Video Progress Data</button>
        <h2 className='des-head'><u>Description</u></h2>
        <p className='des-sub-head'>{props.Des}</p>

      </div>
    </div>
  );
}

export default Video;
