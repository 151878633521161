import React, { useEffect, useState } from "react";
// import data from "./data";
import VideoMobile from "./VideoMobile";
import "../VideoPageMobile.css";
import { useLocation, useParams } from "react-router-dom";
import Api from "../../axios.js";
import getData from "../../utils/getData.js";

const VideoPageMobile = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const params = useParams();

  const [data, setData] = useState();
  const location = useLocation();

  useEffect(() => {
    let langData = getData(params.id);
    if (langData) {
      setData(langData.data);
    }
  }, [params]);
  useEffect(() => {
    console.log(selectedVideo);
  }, [selectedVideo]);

  useEffect(() => {
    console.log(location?.pathname?.split("/")[3]);
    console.log(data);
    let vid = 1;
    if (data) {
      
      data.map((video) => {
        if (video.heading.replaceAll(" ", "+").replaceAll("\u00A0", "+").toLocaleLowerCase() === location?.pathname?.split("/")[3]) {
          vid = video.id;
        }
      });
    }
    setSelectedVideo(location.state?.vid || vid);
  }, [location, data]);

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
      console.log(position.coords.latitude);
      console.log(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
   
    if (data?.find((video) => video.id === selectedVideo)?.heading)
      Api.post("/analytics/sendTrackingData", {
        lat: lat,
        lng: lng,
        country: "",
        video_name: data?.find((video) => video.id === selectedVideo)?.heading,
        videoLanguage: params.id,
        eventType: "CLICK",
        ipv4Address: "",
        ipv6Address: "",
        progress_percentage: 0,
        endpoint: `/challenge/${params.id}/${
          data?.find((video) => video.id === selectedVideo)?.heading
        }`
          .replaceAll(" ", "+").replaceAll("\u00A0", "+")
          .toLowerCase(),
      }).then((res) => {
        console.log(res);
      });
  }, [selectedVideo]);

  return (
    <div>
      {
        // !isFullScreen ? (<NavbarMobile />):""
      }

      {data && selectedVideo && (
        <div className="app-containerm">
          <div className="selected-videom">
            {selectedVideo && (
              <VideoMobile
                className="playing-videom"
                {...data.find((video) => video.id === selectedVideo)}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                data={data}
                showSidebar={showSidebar}
                lang={params.id}
                setShowSidebar={setShowSidebar}
                setSelectedVideo={setSelectedVideo}
                selectedVideo={selectedVideo}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPageMobile;
