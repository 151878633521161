const data = [
    {
        heading: "Excessive Interest",
        id: 1,
        url:"https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Farah English 480p.mp4",
        subs: "/img/Farah.vtt",
        Des: "V2-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        continuable: {
            cando: true,
        },
        questions: [{
                questionText: "Find the red flag activity.<br><br>Make sure the money lenders are legal and reputable.<br><br>Bad lenders often give excessive interest rates, making it difficult to pay back the loan.<br><br>Review the sample loan agreements on the next three pages and find the red flag!",
                continuable: {
                    cando: true,
                },
                
                correctOption: "",
                answerDes: "CSS stands for Cascading Style Sheets. It is a stylesheet language used to describe the presentation of web pages, including elements like colors, fonts, and layout."
            },
            {
                questionText: "<img  loading='lazy' alt='images' src='/img/activity1.png' width='300' height='400'>",
                options: [
                    "Green Flag",
                    "Red Flag"
                ],
                correctOption: "Red Flag",
                answerDesCorrect: "Above 48% interest rate is illegal in Hong Kong, as of January 2023. The money lender wrote an amount that is just below the 48% rate. That is very suspicious! Click continue to see the next loan agreement.",
                answerDesWrong: "Above 48% interest rate is illegal in Hong Kong, as of January 2023. The money lender wrote an amount that is just below the 48% rate. That is very suspicious! Click continue to see the next loan agreement.",
            },
            {
                questionText: "<img  loading='lazy' alt='images' src='/img/acitivity2.png' width='300' height='400'>",
                options: [
                    "Green Flag",
                    "Red Flag"
                ],
                
                correctHead: "Be Careful!",
                correctOption: "Red Flag",
                answerDesCorrect: "Yikes! Interest rates of 48% or above are illegal in Hong Kong! Be cautious of unethical money lenders! Click continue to see the next loan agreement.",
                answerDesWrong: "Yikes! Interest rates of 48% or above are illegal in Hong Kong! Be cautious of unethical money lenders! Click continue to see the next loan agreement."
            },
            {
                questionText: "<img  loading='lazy' alt='images' src='/img/activity3.png' width='300' height='400'>",
                options: [
                    "Green Flag",
                    "Red Flag"
                ],
                newhead: {
                    cando: true,
                    heading: "Be Careful!"
                },
                correctOption: "Green Flag",
                answerDesCorrect: "This loan looks like a possibly good agreement! Make sure to use your best judgment and choose a loan that you can afford to pay back. Click continue to see what happens next.",
                answerDesWrong: "This loan looks like a possibly good agreement! Make sure to use your best judgment and choose a loan that you can afford to pay back. Click continue to see what happens next."
            },
            {
                questionText: "How Should Farah Respond?",
                options: [
                    '"Oh wow! Sounds like a great deal!"',
                    '"Um… I am not sure, I am already paying back this loan, I do not want to take out another…"',
                ],
                correctOption: '"Um… I am not sure, I am already paying back this loan, I do not want to take out another…"',

                answerDesCorrect: "Money lenders are businesses. When they offer to restructure (or re-loan) your debt, you must be careful — especially if they are offering free products or pressuring you to agree to a larger loan.<br><br>A re-loan is when a new loan pays off your old loan and you can borrow more money. Sometimes this can help, but other times it can increase your interest and make your payments too high to manage.<br><br>Before you agree, take time to consider if a re-loan is a good decision and seek help from an NGO or a finance expert to help you decide.",

                answerDesWrong: "Money lenders are businesses. When they offer to restructure (or re-loan) your debt, you must be careful — especially if they are offering free products or pressuring you to agree to a larger loan.<br><br>A re-loan is when a new loan pays off your old loan and you can borrow more money. Sometimes this can help, but other times it can increase your interest and make your payments too high to manage.<br><br>Before you agree, take time to consider if a re-loan is a good decision and seek help from an NGO or a finance expert to help you decide.",
            },
        ],
        tips: "If you decide to take out a loan, make sure to select trustworthy and reputable money lending companies. They should walk you through all of the details of the loan. If you feel uncomfortable or pressured, you can walk away!<br /><br />From January 2023 in Hong Kong, annual interest rates that are above 48% are considered illegal, and the agency is possibly engaging in illegal behavior.<br /><br />Remember: Make sure to keep all receipts, pictures, and documents related to the agency or money lender. Don’t throw anything away, it might help you in the future!<br /><br />If you are being harassed or in danger, please contact your local police.",
        times: [57, 58, 59, 60, 92],
    },
    {
        heading:"Signing for Loans",
        id: 2,
        url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Josie English 480p.mp4",
        subs: "/img/Josie.vtt",
        Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        questions: [{
                questionText: "How should Josie respond?",
                options: [
                    '"Are you sure taking out a loan is a good idea? Are they trustworthy?"',
                    '"Of course, you can just take out a loan!"',
                ],
                correctOption: '"Are you sure taking out a loan is a good idea? Are they trustworthy?"',
                answerDesCorrect: "It's important to be cautious and find reputable, legal money lenders if you take out a loan. Make sure you can afford to pay back the loan. Click continue to see what happens if Josie agrees…",
                answerDesWrong: " It's important to be cautious and find reputable, legal money lenders if you take out a loan. Make sure you can afford to pay back the loan. Click continue to see what happens if Josie agrees…",
            },
            {
                questionText: "How should Josie respond?",
                options: [
                    '"I do not know. To be honest, I do not feel comfortable putting my name down. Maybe let us find another way we can get you that money."',
                    '"Sure, anything to help! Put my name down as a reference!"',
                ],
                correctOption: '"I do not know. To be honest, I do not feel comfortable putting my name down. Maybe let us find another way we can get you that money."',
                answerDesCorrect: " Putting your name down as a reference, guarantor, or co-borrower is extremely risky! If your friend is unable to pay, you might be responsible for paying off the loan yourself. This might put you in a dangerous situation.<br /><br />If you say yes to becoming a reference, you might be saying yes to the whole loan! Click continue to see what happens if Josie agrees to be a reference…",
                answerDesWrong: "Putting your name down as a reference, guarantor, or co-borrower is extremely risky! If your friend is unable to pay, you might be responsible for paying off the loan yourself. This might put you in a dangerous situation.<br /><br />If you say yes to becoming a reference, you might be saying yes to the whole loan! Click continue to see what happens if Josie agrees to be a reference…",
            },
        ],
        tips: "Avoid putting yourself as a reference, co-borrower, or guarantor for another person’s loan application. As a co-borrower or guarantor, by law you would be responsible if the main person left; as a reference, you may or may not be responsible, but a bad money lender might try to collect from you anyways!<br /><br />This is extremely risky and dangerous! Make sure to keep in contact with the main borrower. Remember, if you say yes to a reference, you might be saying yes to the whole loan!<br /><br />If you decide to take out a loan, make sure to select trustworthy and reputable money lending companies. They should walk you through all of the details of the loan, and make sure you read and understand every document you sign!<br /><br />If you feel uncomfortable or pressured, you can walk away!<br />If you are being harassed or in danger, please contact your local police.",
        times: [78, 102],

    },
    {
        heading:  "Love Scams",
        id: 3,
        url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Nina English 480p.mp4",
        subs: "/img/Nina.vtt",
        Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        questions: [{
                questionText: "How should Nina respond?",
                options: [
                    '"Hmmm…we have not really met in-person. I am not sure…"',
                    '"I cannot wait to see you!"',
                ],
                correctOption: '"Hmmm…we have not really met in-person. I am not sure…"',
                answerDesCorrect: "People online may not be who they say they are! Scammers may use AI and Deep Fake technology to change their voice and image online. Be extra careful when meeting new people both online and in-person!<br /><br />Scammers may come up with many reasons not to have a phone call and constantly avoid meeting in person. They may also promise gifts to build trust with you. If you do not know this person, it is good to be suspicious.<br /><br />Click continue to see what happens if Nina continues to believe John.",
                answerDesWrong: " People online may not be who they say they are! Scammers may use AI and Deep Fake technology to change their voice and image online. Be extra careful when meeting new people both online and in-person!<br /><br />Scammers may come up with many reasons not to have a phone call and constantly avoid meeting in person. They may also promise gifts to build trust with you.  If you do not know this person, it is good to be suspicious.<br /><br />Click continue to see where the story goes.",
            },
            {
                questionText: "How should Nina respond?",
                options: [
                    '"I do not know. It feels unsafe."',
                    '"Do not worry. I can send the money!"',
                ],
                correctOption: '"I do not know. It feels unsafe."',
                answerDesCorrect: "Love scammers will play tricks and mind games to ask you to send money or commit illegal acts. It's best to be cautious to avoid becoming a victim!<br /><br />Click continue to see what happens if Nina continues to believe John.",
                answerDesWrong: "Love scammers will eventually play tricks and mind games to ask you to send money or commit illegal acts. It's best to be cautious to avoid becoming a victim!<br /><br />Click continue to see where the story goes.",
            },
        ],
        tips: "Love scammers may trick you by pretending to be rich and powerful. They are also experts at building trust by pretending to care about you and quickly build a relationship. They will use these mind games to trick you.<br /><br />Scammers may use AI and Deep Fake technology to change their voice and image! Be aware of new people you meet online and in-person.<br /><br />If you are in Hong Kong and have doubts or are suspicious of a scam, you may call 18222  to reach the Anti-Scam Helpline of the Anti-Deception Coordination Centre (ADCC) of the Hong Kong Police Force.<br /><br />If you are being harassed or in danger, please contact your local police.",
        times: [91, 133],

    },
    // Add more videos here
];
let pages = {
    home: {
      title: " Take Your FinLit Challenge Now",
      button: "Start",
      route: "english",
      container: {
        title: "Our Challenges",
  
        card1: {
          title: "Excessive Interest",
        },
        card2: {
          title: "Signing for Loans",
        },
        card3: {
          title: "Love Scams",
        },
      },
      thanks: {
        title: "Special Thanks to our Voice Artists!",
        names: ["Jessie Hsia",
            "Suci Ayu Rufitriani",
            "Paras Kalura",
            "Cherelyn Trilles",
            "Nice Salarda",
            "Clemens",
            "Maria Cristina Delfin Arcilla",
            "Ruth Judea Puentespina"
        ]
      }
    },
    video:{
        home:"Home",
        more:"More Challenges"
    }
  };
  module.exports.constantsEnglish = { pages, data };