import React, { useEffect } from "react";
import { FaPlayCircle } from "react-icons/fa";
import "../ourchallengesMobile.css";
import { Route, useNavigate, useLocation } from "react-router-dom";
import VideoPageMobile from "./VideoPageMobile";

export default function OurChallenges({ pageData,pageData2, lang }) {
  const navigate = useNavigate();
  const location = useLocation();
  const selectVid = (id, name) => {
    console.log(id);
    navigate(`/challenge/${lang}/${name}`.replaceAll(" ", "+").replaceAll("\u00A0", "+").toLowerCase(), {
      state: { vid: id },
    });
  };

  return (
    <div className="challengem">
      <h2 className="challenge-headingm">
        {" "}
        {pageData?.title || "Our Challenges"}
      </h2>

      <div className="Challenge-video-continerm">
        {/* <div className='main-v-containerm'>

          <img  loading='lazy' alt='images' className='Challenge-main-videom' src='https://static.wixstatic.com/media/92d664_eb9649b27adc4646848c158bbcce3e8b~mv2.jpg/v1/fill/w_1519,h_651,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/92d664_eb9649b27adc4646848c158bbcce3e8b~mv2.jpg'></img>

          <a href="/challengesM">
          <div className='main-video-btnm'>
            <a href='/challengesM' className='main-video-text'>Choose a language</a>
            <FaPlayCircle size={40} />

            
          </div>
          </a>
        </div> */}

        <div className="Challenge-sub-videom">
          <div
            className="cardm"
            style={{ cursor: "pointer" }}
            onClick={() => {
              selectVid(1, pageData2?.card1?.title || "Excessive Interest");
            }}
          >
            <img
              className="sub-img"
              src="https://empoweruvids.s3.ap-south-1.amazonaws.com/images/Excessive.png"
            ></img>
            <div className="video-timem">02:00</div>
            <div className="card-bottomm">
              <p className="card-headingm">
                {" "}
                {pageData?.card1?.title || "Excessive Interest"}
              </p>
              <FaPlayCircle
                size={35}
                color="#03045e"
                style={{ paddingTop: "6px" }}
              />
            </div>
          </div>
          <div
            className="cardm"
            style={{ cursor: "pointer" }}
            onClick={() => {
              selectVid(2, pageData?.card2?.title || "Signing for Loans");
            }}
          >
            <img
              className="sub-imgm"
              src="https://empoweruvids.s3.ap-south-1.amazonaws.com/images/Loans.png"
            ></img>
            <div className="video-timem ">02:22</div>
            <div className="card-bottomm">
              <p className="card-headingm">
                {" "}
                {pageData?.card2?.title || "Signing for Loans"}
              </p>
              <FaPlayCircle
                size={35}
                color="#03045e"
                style={{ paddingTop: "6px" }}
              />
            </div>
          </div>
          <div
            className="cardm"
            style={{ cursor: "pointer" }}
            onClick={() => {
              selectVid(3, pageData?.card3?.title || "Love Scams");
            }}
          >
            <img
              className="sub-imgm"
              src="https://empoweruvids.s3.ap-south-1.amazonaws.com/images/Love.png"
            ></img>
            <div className="video-timem">03:00</div>
            <div className="card-bottomm">
              <p className="card-headingm">
                {pageData?.card3?.title || "Love Scams"}
              </p>
              <FaPlayCircle
                size={35}
                color="#03045e"
                style={{ paddingTop: "6px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
