import { useRef } from "react";
import { FaBars, FaTimes, FaAngleDown, FaHome } from "react-icons/fa";
import { BiGlobe } from "react-icons/bi";
import { Route, useNavigate, useLocation } from "react-router-dom";
import "./navBarMobile.css";

function NavbarMobile() {
	// const navRef = useRef();

	// const showNavbar = () => {
	// 	navRef.current.classList.toggle(
	// 		"responsive_nav"
	// 	);
	// };

	const navigate = useNavigate();

	const home = () => {
		navigate("/");
	};

	const handleLanguage = (e) => {
		console.log(e.target.value);
		if (e.target.value === "English") {
			window.location.href = "/english";
		} else if (e.target.value === "Bahasa") {
			window.location.href = "/bahasa";
		} else if (e.target.value === "Malay") {
			window.location.href = "/malay";
		} else if (e.target.value === "Filipino") {
			window.location.href = "/filipino";
		}
	};

	return (
        <>
		<header>
			<a href="/">
            <img  loading='lazy' alt='images' className='nav-logo' src='https://static.wixstatic.com/media/841666_afc4c245ff97418f9aea07245c684774~mv2.png/v1/fill/w_114,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/EmpowerU%20won-wider.png'></img>
			</a>
			{/* <nav ref={navRef}>
				<a href="https://www.empoweru.io/about">About</a>
				<a href="https://www.empoweru.io/programs">Programs</a>
				<a href="https://www.empoweru.io/blog">Blog</a>
				<a href="https://www.empoweru.io/contact">Get Involved</a>
			
			</nav> */}
			<div className="nav-btns">
				{/* <div className="lang">
					<BiGlobe color="#ae0157" size={20}/>
					<select className="lang-menu" style={{scale:"1.3"}} onChange={(e) => handleLanguage(e)}>
						<option value="English">English</option>
						<option value="Bahasa">Bahasa</option>
						<option value="Malay">Malay</option>
						<option value="Filipino">Filipino</option>
					</select>
				</div> */}
				{/* <FaAngleDown color="#ae0157"/> */}
				{/* <button
					className="nav-btn"
					onClick={showNavbar}>
					<FaBars color="#ae0157"/>
				</button> */}
				<FaHome  color="#ae0157" 
					style={{
						// marginTop: "0.5rem",
						// marginRight: "1rem",
						margin: "0 0.5rem",
						fontSize: "1.8rem",
						cursor: "pointer",
					}}
					onClick={home} 
					
				/>
			</div>
		</header>
        <div className='nav-grey-line'></div>
        </>
        
	);
}

export default NavbarMobile;