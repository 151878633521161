import React, { useEffect, useState, Component } from "react";
import Hero from "../comp/Hero";
import HeroMobile from "../comp/english/HeroMobile";
import Navbar from "../comp/Navbar";
import NavbarMobile from "../comp/english/NavbarMobile";
import OurChallenges from "../comp/OurChallenges";
import OurchallengesMobile from "../comp/english/OurchallengesMobile";
import Footer from "../comp/Footer";
import FooterMobile from "../comp/english/FooterMobile";
import VideoPageMobile from "../comp/english/VideoPageMobile";
// import VideoPage from '../comp/english/VideoPage';
import Api from "../axios.js";
import englishapages from "../comp/Web/english.js";
import { useParams } from "react-router-dom";
import getData from "../utils/getData.js";
import SpecialThanks from "../comp/SpecialThanks.jsx";

function Home() {
  const [isMobileView, setIsMobileView] = useState(false);
  const data = useParams();
  const [dataValues, setDataValues] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    let langData = getData(data.id);
    if (langData) {
      setDataValues(langData.pages);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 821);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

 
  return (
    <div>
      {loading ? (
        <div>
          <h1
            style={{
              textAlign: "center",
              marginTop: 50,
              fontFamily: "sans-serif",
              width: "100%",
            }}
          >
            Loading...
          </h1>
        </div>
      ) : dataValues && (
        <div>
          {isMobileView ? (
            <div>
              <NavbarMobile />
              <HeroMobile lang={data.id}  pageData2={dataValues?.home?.container} pageData={dataValues?.home} />
              <OurchallengesMobile
                lang={data.id || "english"}
                pageData={dataValues?.home?.container}
              />
              <SpecialThanks pageData={dataValues?.home?.thanks} />
              <FooterMobile />
            </div>
          ) : (
            <div>
              <Navbar />
              <Hero lang={data.id} pageData2={dataValues?.home?.container} pageData={dataValues?.home} />
              <OurChallenges
                lang={data.id}
                pageData={dataValues?.home?.container}
              />
              <SpecialThanks pageData={dataValues?.home?.thanks} />
              <Footer />
            </div>
          )}

          {/* <OurChallenges/>
            <Footer/> */}
        </div>
      ) }
    </div>
  );
}

export default Home;
