import React, { useState } from "react";
// import Marquee from "react-fast-marquee";


export default function SpecialThanks({ pageData, lang }) {
    const thanksList = pageData?.names;
    const names = [
        "Jessie Hsia",
        "Suci Ayu Rufitriani",
        "Paras Kalura",
        "Cherelyn Trilles",
        "Nice Salarda",
        "Clemens",
        "Maria Cristina Delfin Arcilla",
        "Ruth Judea Puentespina"
    ];

    return (
        <div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '20vh',
                width: '100%',
                gap: '1rem',
                fontFamily: 'sans-serif',
            }}
        >
            <h2
                style={{
                    fontWeight: 'bold',
                    color: '#000000',
                    textAlign: 'center',
                    width: '100%',
                    fontFamily: 'sans-serif',
                    fontStyle: "italic"
                
                }}
            >
                {pageData?.title}
            </h2>
            <marquee
                direction="left"
                scrollamount="10"
            >
                {
                    pageData?.names ?
                        thanksList.map((name, index) =>
                            <span key={index} style={{ padding: '0 2rem', fontSize: "1.5rem" }}>{name}</span>
                        ) :
                        names.map((name, index) =>
                            <span key={index} style={{ padding: '0 2rem', fontSize: "1.5rem" }}>{name}</span>
                        )
                }
            </marquee>
        </div>
    );
}