import React from 'react'
import "./footer.css";


export default function Footer() {
    return (
        <div className='footer'>
            <div className='footer-items'>
                {/* <a href='https://www.empoweru.io/about' className='foot-item'>About</a> */}
                <a href='https://www.empoweru.io/faqs' className='foot-item'>FAQs</a>
                <a href='https://www.empoweru.io/privacy' className='foot-item'>Privacy</a>
                <a href='https://www.empoweru.io/terms-of-use' className='foot-item'>Terms of Use</a>
                {/* <a href='https://www.empoweru.io/contact' className='foot-item'>Get Involved</a> */}
            </div>

            <div className='foot-share'>

                <a href='https://www.facebook.com/EmpowerUHK/'>
                    <img  loading='lazy' alt='images' className='footer-ico' src="https://static.wixstatic.com/media/aebe5b6fd55f471a936c72ff2c8289d7.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/aebe5b6fd55f471a936c72ff2c8289d7.png"></img>
                </a>


                <a href='https://www.instagram.com/empoweruhk/'>
                    <img  loading='lazy' alt='images' className='footer-ico' src="https://static.wixstatic.com/media/40898a93cfff4578b1779073137eb1b4.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/40898a93cfff4578b1779073137eb1b4.png"></img>
                </a>

                <a href='https://www.linkedin.com/company/empoweruhk?originalSubdomain=hk'>
                    <img  loading='lazy' alt='images' className='footer-ico' src="https://static.wixstatic.com/media/fcfebdfae12a44f993a94aeed8e2e66b.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/fcfebdfae12a44f993a94aeed8e2e66b.png"></img>
                </a>

            </div>

            {/* <div className='foot-policy'>
                <p className='foot-policy'>© Migrasia Global Solutions Limited 2022  — a <a href='https://www.shared-impact.com/'> <u>Foundation for Shared Impact </u> </a>partner</p>

            </div> */}


        </div>
    )
}
