import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import "../videoMobile.css";

import {
  FaBars,
  FaTimes,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";

import { MdSkipNext, MdReplay, MdHome } from "react-icons/md";
import screenfull from "screenfull";

import Confetti from "react-confetti";

import {
  AiFillCaretRight,
  AiOutlinePause,
  AiOutlineExpand,
  AiOutlineCompress,
} from "react-icons/ai";
import Api from "../../axios";
import { useNavigate } from "react-router-dom";
import getData from "../../utils/getData";

function VideoMobile(props) {
  const [playing, setPlaying] = useState(false);
  const [data, setData] = useState([]);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestions, setShowQuestions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentTime, setCurrentTime] = useState(-1);
  const [dataValues, setDataValues] = useState();
  const [videoDuration, setVideoDuration] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [answerDes, setAnswerDes] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [videoProgressData, setVideoProgressData] = useState({});
  const [isBarAppere, setIsBarAppere] = useState(true);

  // const [showSidebar, setShowSidebar] = useState(true);

  let setIsFullScreen = props.setIsFullScreen;
  let isFullScreen = true;
  let selectedVideo = props.selectedVideo;
  let setSelectedVideo = props.setSelectedVideo;
  let showSidebar = props.showSidebar;
  let setShowSidebar = props.setShowSidebar;

  const playerRef = useRef(null);
  // const handle = useFullScreenHandle();

  const togglePlay = () => {
    console.log("toggle play");
    setPlaying(!playing);
    console.log(playing);
  };
  useEffect(() => {
    let langData = getData(props.lang);
    console.log({ props });
    if (langData) {
      setDataValues(langData.pages.video);
    }
    setData(props.data);
  }, [props]);
  const videoRef = useRef(null);

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen((prevFullScreen) => !prevFullScreen);
    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request();
      }
    }
  };

  const handleVideoClick = async(videoId, name) => {
    console.log({ videoId, name });
    await navigate (
      `/challenge/${props.lang}/${name}`
        .replaceAll(" ", "+")
        .replaceAll("\u00A0", "+")
        .toLocaleLowerCase(),
      { state: { vid: videoId } }
    );
    setSelectedVideo(videoId);
    setShowSidebar(false);
    
  };

  const handleSpeedChange = (newSpeed) => {
    setPlaybackRate(newSpeed);
  };

  useEffect(() => {
    setCurrentQuestionIndex(0);
    setShowQuestions(false);
    console.log(props);
    console.log(selectedVideo);
    if (props.url) {
      setPlaying(true);
    }
  }, [props.url]);

  const [progressPerecentage, setProgressPerecentage] = useState(0);

  useEffect(() => {
    const progress = parseInt((currentTime / videoDuration) * 100);
    setProgressPerecentage(progress);
  
  
  }, [currentTime, videoDuration]);

  useEffect(()=>{
    if(progressPerecentage!=0){
      if (progressPerecentage === 1){
        trackProgress();
      }
      if (progressPerecentage % parseInt(25) == 0) {
        trackProgress();
      }
    }
  },[progressPerecentage])
  const directSubmit = (option) => {
    const correctAnswer = props.questions[currentQuestionIndex].correctOption;
    const isCorrect = option === correctAnswer;

    setIsAnswerCorrect(isCorrect);

    setIsAnswerSubmitted(true);
    setShowAnswer(true);

    if (isCorrect) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setShowQuestions(false);
      setShowAnswer(true); // Hide answer message for the next question
      setAnswerDes(true);
    } else {
      setShowQuestions(false);
      // setPlaying(true);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setShowAnswer(true); // Show answer message for incorrect answer
      setAnswerDes(true);
    }
    setSelectedOption(null);
  };

  const trackProgress = () => {
    Api.post("/analytics/sendTrackingData", {
      lat: lat,
      lng: lng,
      country: "",
      video_name: props.heading,
      videoLanguage: props.lang,
      eventType: "PROGRESS",
      ipv4Address: "",
      ipv6Address: "",
      progress_percentage: progressPerecentage,
      endpoint: `/challenge/${props.lang}/${props?.heading}`
        .replaceAll(" ", "+")
        .replaceAll("\u00A0", "+")
        .toLocaleLowerCase(),
    }).then((res) => {
      console.log(res);
    });
  };
  const finalSubmit = () => {
    Api.post("/analytics/sendTrackingData", {
      lat: lat,
      lng: lng,
      country: "",
      video_name: props.heading,
      eventType: "PROGRESS",
      ipv4Address: "",
      ipv6Address: "",
      videoLanguage: props.lang,
      progress_percentage: 100,
      endpoint: `/challenge/${props.lang}/${props?.heading}`
        .replaceAll(" ", "+")
        .replaceAll("\u00A0", "+")
        .toLocaleLowerCase(),
    }).then((res) => {
      console.log(res);
    });
  };

  const navigate = useNavigate();

  const nextVid = (id) => {
    navigate(
      `/challenge/${props.lang}/${props?.heading}`
        .replaceAll(" ", "+")
        .replaceAll("\u00A0", "+")
        .toLocaleLowerCase(),
      { state: { vid: id } }
    );
  };

  const goToHome = () => {
    navigate("/" + props.lang);
  };

  const ansSkipBtn = () => {
    setAnswerDes(false);
    setPlaying(true);
  };

  const handleProgress = (state) => {
    const currentTime = state.playedSeconds;

    if (currentTime >= props.times[currentQuestionIndex]) {
      setShowQuestions(true);
      setPlaying(false);

      const prevProgress = videoProgressData[props.id]
        ? videoProgressData[props.id].progress
        : 0;

      if (currentTime > prevProgress) {
        setVideoProgressData((prevProgressData) => ({
          ...prevProgressData,
          [props.id]: {
            id: props.id,
            progress: currentTime,
          },
        }));

        localStorage.setItem(
          "userVideoProgress",
          JSON.stringify(videoProgressData)
        );
      }
    } else {
      setVideoProgressData((prevProgressData) => ({
        ...prevProgressData,
        [props.id]: {
          id: props.id,
          progress: currentTime,
        },
      }));

      localStorage.setItem(
        "userVideoProgress",
        JSON.stringify(videoProgressData)
      );
    }
  };

  useEffect(() => {
    const storedProgressData = localStorage.getItem("userVideoProgress");
    if (storedProgressData) {
      const parsedProgressData = JSON.parse(storedProgressData);
      setVideoProgressData(parsedProgressData);
    }
  }, []);

  function clearVideoProgressData() {
    localStorage.removeItem("userVideoProgress");
    setVideoProgressData({});
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const formatDuration = (duration) => {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const toggleTimelineVisibility = () => {
    console.log("fullscreen is ", isFullScreen);

    setIsBarAppere(!isBarAppere);

    setTimeout(() => {
      if (isBarAppere) setIsBarAppere(false);
    }, 4000);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsBarAppere(true);
      console.log("fullsceen is  true cl");
    }, 4000);
  }, [props]);

  const [checkVideoCompletion, setCheckVideoCompletion] = useState(false);
  const [semiCompletion, setSemiCompletion] = useState(false);

  useEffect(() => {
    if (currentTime >= videoDuration) {
      // setCheckVideoCompletion(true);
      setSemiCompletion(true);
      finalSubmit();
    }
  }, [currentTime, videoDuration]);

  const Tips = () => {
    return (
      <div
        style={{
          overflowY: "scroll",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          className="tips-container"
          style={{
            overflowY: "scroll",
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <div
            className="tips-heading"
            style={{
              padding: "10px",
              backgroundColor: "skyblue",
              borderRadius: "10px",
              minWidth: "300px",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
            }}
          >
            <h2
              style={{
                color: "white",
                textAlign: "center",
              }}
            >
              {selectedVideo == 3
                ? dataValues.challenge || "All challenges Completed"
                : dataValues?.tips || "Tips"}
            </h2>
          </div>
          <div
            className="tips-content"
            style={{
              padding: "30px",
              textAlign: "justify",
              fontFamily: " sans-serif",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: props.tips }}></div>
          </div>
          <div
            className="tips-btn"
            style={{
              marginBottom: "20px",
            }}
          >
            <button
              style={{
                color: "white",
                backgroundColor: "#03045e",
                borderRadius: "5px",
                height: "40px",
                width: "100px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                if (selectedVideo == 3) {
                  navigate("/");
                } else {
                  setSemiCompletion(false);
                  setCheckVideoCompletion(true);
                }
              }}
            >
              {dataValues?.continue || "Continue"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ConfettiBackground = () => {
    return (
      <div>
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={150} // You can adjust the number of confetti pieces
        />
        <div className="content">
          <div
            className="video-completion"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              width: "100vw",
              background: "black",
            }}
          >
            <h1
              style={{
                paddingBottom: "20px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {dataValues.challenge || "Challenge Completed"}
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                width: "100vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "20px",
                  paddingRight: "20px",
                }}
                onClick={() => {
                  setCheckVideoCompletion(false);
                  setCurrentTime(0);
                  setPlaybackRate(1);
                  setCurrentQuestionIndex(0);
                  setShowQuestions(false);
                  setAnswerDes(false);
                  clearVideoProgressData();
                  nextVid(selectedVideo);
                }}
              >
                <MdReplay size={90} color="white" />
                <h4
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {dataValues.play || "Play Again"}
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "20px",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
                onClick={() => {
                  setCheckVideoCompletion(false);
                  setCurrentTime(0);
                  setPlaybackRate(1);
                  goToHome();
                }}
              >
                <MdHome size={90} color="white" />
                <h4
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {dataValues?.back || "Go back home"}
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "20px",
                  paddingLeft: "20px",
                }}
                onClick={() => {
                  if (selectedVideo <= data.length) {
                    setCheckVideoCompletion(false);
                    setCurrentTime(0);
                    setPlaybackRate(1);
                    // setSelectedVideo((selectedVideo + 1)%5);
                    if (selectedVideo + 1 === 4) {
                      nextVid(1);
                    } else {
                      nextVid((selectedVideo + 1) % 4);
                    }
                    console.log((selectedVideo + 1) % 4);
                  }
                }}
              >
                <MdSkipNext size={90} color="white" />
                <h4
                  style={{
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {dataValues?.next || " Next Challenge"}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="full-wrapper">
      {checkVideoCompletion ? (
        <ConfettiBackground />
      ) : semiCompletion ? (
        <Tips selectedVideo={props.selectedVideo} />
      ) : (
        <div className="player-wrapperm">
          <div className={`custom-video-playerm ${isFullScreen ? "" : ""}`}>
            <div onClick={toggleTimelineVisibility}>
              <ReactPlayer
                key={`p1-${props.subs}`}
                ref={playerRef}
                url={props.url}
                controls={false}
                onProgress={(progress) => {
                  setCurrentTime(progress.playedSeconds);
                  handleProgress(progress); // Call your handleProgress function here
                }}
                playing={playing}
                className="video"
                playbackRate={playbackRate}
                width={isFullScreen ? "100vw" : "100vw"}
                height={isFullScreen ? "100vh" : "100vh"}
                onDuration={(duration) => setVideoDuration(duration)}
                config={{
                  file: {
                    tracks: [
                      {
                        kind: "subtitles",
                        src: `${props.subs}`,
                        srcLang: "en",
                        default: true,
                      },
                    ],
                  },
                }}
                playsinline={true}
                style={{ height: "1030px" }}
                
              />
            </div>
            {isBarAppere ? (
              <div
                style={{ background: "white" }}
                className={`custom-controlsm ${
                  isFullScreen ? "" : "fullscreen"
                }`}
              >
                <div
                  className={`btn-timem ${isFullScreen ? "" : "fullscreen"}`}
                >
                  <p
                    onClick={() => {
                      togglePlay();
                      setAnswerDes(false);
                    }}
                    className="playm"
                  >
                    {playing ? <AiOutlinePause /> : <AiFillCaretRight />}
                  </p>

                  {/* <div className='time-barm'> */}

                  <p className="time">
                    {" "}
                    {formatTime(currentTime)} : {formatDuration(videoDuration)}
                  </p>

                  {/* </div> */}
                </div>

                <input
                  type="range"
                  min="0"
                  max={videoDuration}
                  value={currentTime}
                  className="slider"
                  id="myRange"
                  currentTime={currentTime}
                  videoDuration={videoDuration}
                  readOnly
                />

                <div
                  className={`fullscreen-speedm ${
                    isFullScreen ? "" : "fullscreen"
                  }`}
                >
                  <select
                    style={{ color: "grey" }}
                    className={`speedm ${isFullScreen ? "" : "fullscreen"}`}
                    onChange={(e) =>
                      handleSpeedChange(parseFloat(e.target.value))
                    }
                  >
                    <option className="optionm" id="first-op" value="1">
                      Speed
                    </option>
                    <option className="optionm" value="0.5">
                      0.5x
                    </option>
                    <option className="optionm" value="1">
                      1x
                    </option>
                    <option className="optionm" value="1.5">
                      1.5x
                    </option>
                    <option className="optionm" value="2">
                      2x
                    </option>
                  </select>

                  <p onClick={toggleFullScreen} className="fullscreen-btn">
                    {isFullScreen ? <AiOutlineCompress /> : <AiOutlineExpand />}
                  </p>
                </div>
              </div>
            ) : (
              <p></p>
            )}
            {!showSidebar ? (
              <button
                className={`sidebar-toggle-buttonm ${
                  isFullScreen ? "fullscreen" : ""
                }`}
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <FaAngleDoubleLeft />
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="video-bottomm">
            {showQuestions && (
              <form
                className={`form-containerm ${
                  isFullScreen ? "fullscreen" : ""
                }`}
              >
                <div className="form-options">
                  <p
                    className={`form-Qusm ${isFullScreen ? "fullscreen" : ""}`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          props.questions[currentQuestionIndex].questionText,
                      }}
                    ></div>
                    {/* {props.questions[currentQuestionIndex].questionText} */}
                  </p>

                  {props.questions[currentQuestionIndex].options?.map(
                    (option, index) => (
                      <div key={index} className="form-o-b">
                        <input
                          type="radio"
                          id={`option${index}`}
                          name="options"
                          value={option}
                          className="styled-radiom"
                          // checked={selectedOption === option}
                          onChange={(op) => {
                            // setSelectedOption(option)
                            console.log(op);
                            directSubmit(op.target.value);
                          }}
                          // onClick={() => {
                          //   directSubmit(option);
                          // }}
                        />

                        <label className="form-Opm" htmlFor={`option${index}`}>
                          <div
                            dangerouslySetInnerHTML={{ __html: option }}
                          ></div>
                          {/* {option} */}
                        </label>
                      </div>
                    )
                  )}
                </div>
                <div
                  className={`form-btn-div ${isFullScreen ? "fullscreen" : ""}`}
                >
                  {props.questions[currentQuestionIndex].continuable?.cando ===
                  true ? (
                    <button
                      onClick={() => {
                        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                        setShowQuestions(false);
                        setPlaying(true);
                      }}
                      className={`form-btnm ${
                        isFullScreen ? "fullscreen" : ""
                      }`}
                      style={{
                        width: "130px",
                      }}
                    >
                      {dataValues?.continue || "Continue"}
                    </button>
                  ) : // <button
                  //   onClick={handleQuestionSubmit}
                  //   className={`form-btnm ${isFullScreen ? "fullscreen" : ""}`}
                  // >
                  //   Submit
                  // </button>
                  null}
                  {/* <button
                      onClick={handleQuestionSubmit}
                      className={`form-btnm ${isFullScreen ? "fullscreen" : ""}`}
                    >
                      Submit
                    </button> */}
                </div>
              </form>
            )}
          </div>

          <div
            style={{
              overflowY: "scroll",
            }}
          >
            {answerDes ? (
              <p
                className={`answer-containerm ${
                  isFullScreen ? "fullscreen" : ""
                }`}
              >
                {isAnswerCorrect ? (
                  <div
                    style={{
                      overflowY: "scroll",
                    }}
                  >
                    {currentQuestionIndex - 1 < 0 ? (
                      props.questions[0].newhead?.cando === true ? (
                        <h3 className="ans-w">
                          {props.questions[0].newhead?.heading}
                        </h3>
                      ) : (
                        <h3 className="ans-c">
                          {dataValues.good || "Good Job!"}
                        </h3>
                      )
                    ) : props.questions[currentQuestionIndex - 1].newhead
                        ?.cando === true ? (
                      <h3 className="ans-w">
                        {
                          props.questions[currentQuestionIndex - 1].newhead
                            ?.heading
                        }
                      </h3>
                    ) : (
                      <h3 className="ans-c">
                        {dataValues.good || "Good Job!"}
                      </h3>
                    )}
                    {/* <h3 className="ans-c">Good Job!</h3> */}
                    <p>
                      {currentQuestionIndex - 1 < 0 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.questions[0].answerDesCorrect,
                          }}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              props.questions[currentQuestionIndex - 1]
                                .answerDesCorrect,
                          }}
                        ></div>
                      )}
                      {/* <div dangerouslySetInnerHTML={{ __html: props.questions[currentQuestionIndex-1].answerDesCorrect}}></div> */}
                      {/* {props.questions[currentQuestionIndex - 1].answerDesCorrect} */}
                    </p>
                    <div className="ans-btn-con">
                      <button onClick={ansSkipBtn} className="ansPlayBtn">
                        {dataValues?.continue || "Continue"}
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {currentQuestionIndex - 1 < 0 ? (
                      props.questions[0].newhead?.cando === true ? (
                        <h3 className="ans-w">
                          {props.questions[0].newhead?.heading}
                        </h3>
                      ) : (
                        <h3 className="ans-w">
                          {dataValues.red || "Red Flag!"}
                        </h3>
                      )
                    ) : props.questions[currentQuestionIndex - 1].newhead
                        ?.cando === true ? (
                      <h3 className="ans-w">
                        {
                          props.questions[currentQuestionIndex - 1].newhead
                            ?.heading
                        }
                      </h3>
                    ) : (
                      <h3 className="ans-w">{dataValues.red || "Red Flag!"}</h3>
                    )}
                    {/* <h3 className="ans-w">Red Flag!</h3> */}
                    <p>
                      {currentQuestionIndex - 1 < 0 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.questions[0].answerDesWrong,
                          }}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              props.questions[currentQuestionIndex - 1]
                                .answerDesWrong,
                          }}
                        ></div>
                      )}
                      {/* <div dangerouslySetInnerHTML={{ __html: props.questions[currentQuestionIndex-1].answerDesWrong}}></div> */}
                      {/* {props.questions[currentQuestionIndex - 1].answerDesWrong} */}
                    </p>
                    <div className="ans-btn-con">
                      <button onClick={ansSkipBtn} className="ansPlayBtn">
                        {dataValues?.continue || "Continue"}
                      </button>
                    </div>
                  </>
                )}
              </p>
            ) : (
              <br></br>
            )}
          </div>

          {showSidebar ? (
            <div className={`video-listm ${isFullScreen ? "fullscreen" : ""}`}>
              <div
                className="back-home"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  padding: "10px",
                  backgroundColor: "#03045e",
                  backgroundColor: "#AE0057",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
                }}
                onClick={() => {
                  setShowSidebar(false);
                  goToHome();
                }}
              >
                <MdHome
                  onClick={() => {
                    setShowSidebar(false);
                    goToHome();
                  }}
                  size={28}
                  color="white"
                />
                <h4
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontFamily: "sans-serif",
                    marginTop: 5,
                    marginLeft: 10,
                  }}
                >
                  {dataValues?.home || "Home"}
                </h4>
              </div>
              <div className="list-top">
                <h2 className="video-list-btn">
                  {dataValues?.more || "More Challenges"}
                </h2>
                <div
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                  className="video-list-close"
                >
                  <FaAngleDoubleRight />
                </div>
              </div>
              {data.map((information, index) => (
                <div
                  key={index}
                  className={`video-itemm ${
                    information.id === selectedVideo ? "selected" : ""
                  }`}
                  onClick={async() => {
                    console.log({ information });
                    await handleVideoClick (information.id, information.heading);
                  }}
                >
                  {information.heading}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          <div className={`des-containerm ${isFullScreen ? "fullscreen" : ""}`}>
            <h2 className="des-headm">
              <u>Video Progress Data</u>
            </h2>
            {Object.values(videoProgressData).map((video, index) => (
              <p key={index}>
                Video ID: {video.id}, Progress: {video.progress}
              </p>
            ))}
            <button onClick={clearVideoProgressData}>
              Clear Video Progress Data
            </button>
            <h2 className="des-headm">
              <u>Description</u>
            </h2>
            <p className="des-sub-headm">{props.Des}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoMobile;
