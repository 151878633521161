const data = [{
        heading: "Grace",
        id: 1,
        url: "/img/Grace.mp4",
        Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        questions: [{
                questionText: "How should Grace respond?",
                options: [
                    "No thank you, I think I can study for the test on my own.",
                    "Thank you so much! I want to learn more skills!",
                ],
                correctOption: "No thank you, I think I can study for the test on my own.",
                answerDesCorrect: "Good job! Training may not be required for application (for example, in the Philippines, you only need to pass the NC II exam). If a potential employer asks that you take a training, they have to pay for it.",
                answerDesWrong: "Red flag! Training may not be required for application (for example, in the Philippines, you only need to pass the NC II exam). If a potential employer asks that you take a training, they have to pay for it."
            },
            {
                questionText: "How should Grace respond?",
                options: [
                    "No thank you, I will find my own doctor.",
                    "Okay, I really should get a check-up. I'll go to your doctor.",
                ],
                correctOption: "No thank you, I will find my own doctor.",
                answerDesCorrect: "Good job! You can go to a recommended doctor, but they should not REQUIRE a certain doctor or facility. You should also first check that the facility is licensed and certified by your government. Next, make sure the clinic charges are fair. To check if the clinic is licensed, call the Department of Migrant Workers in the Philippines or BP2MI in Indonesia. Agencies often trick you into paying for unnecessary check-ups so be careful! Click continue to see where the story goes.",
                answerDesWrong: "Red Flag! You can go to a recommended doctor, but they should not REQUIRE a certain doctor or facility. You should also first check that the facility is licensed and certified by your government. Next, make sure the clinic charges are fair. To check if the clinic is licensed, call the Department of Migrant Workers in the Philippines or BP2MI in Indonesia. Agencies often trick you into paying for unnecessary check-ups so be careful! Click continue to see where the story goes."
            },
            {
                questionText: "How should Grace respond?",
                options: [
                    "I shouldn't take out a loan if I can't pay it back...",
                    "That's a good idea! I'll pay you back in Hong Kong!"
                ],
                correctOption: "I shouldn't take out a loan if I can't pay it back...",
                answerDesCorrect: "Good job! Taking out a loan before you leave your country can make you stuck in debt before you can earn and send money! Agencies are also not supposed to refer you. Whenever agencies refer you to a moneylender, they are probably working together. It is best not to take out a loan. But, if you take out a loan, check your monthly salary to make sure you can afford to pay back each month. Click continue to see what happens if Grace continues with the loan…",
                answerDesWrong: "Red flag! Taking out a loan before you leave your country can make you stuck in debt before you can earn and send money! Agencies are also not supposed to refer you. Whenever agencies refer you to a moneylender, they are probably working together. It is best not to take out a loan. But, if you take out a loan, check your monthly salary to make sure you can afford to pay back each month. Click continue to see what happens if Grace continues with the loan…"
            },
        ],
        times: [108, 123, 143], // Time intervals for each question
    },
    {
        heading: "Farah",
        id: 2,
        url: "/img/Farah.mp4",
        Des: "V2-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        questions: [{
                questionText: "What does CSS stand for?",
                options: [
                    "Cascading Style Sheets",
                    "Computer Style Sheets",
                    "Colorful Style Sheets",
                    "Cascading Styling System"
                ],
                correctOption: "Cascading Style Sheets",
                answerDes: "CSS stands for Cascading Style Sheets. It is a stylesheet language used to describe the presentation of web pages, including elements like colors, fonts, and layout."
            },
            {
                questionText: "Which property is used to change the text color in CSS?",
                options: [
                    "color",
                    "text-color",
                    "font-color",
                    "text-style"
                ],
                correctOption: "color",
                answerDes: "The 'color' property is used to change the text color in CSS. It allows you to specify the color of text content."
            },
            {
                questionText: "What is the purpose of the 'display' property in CSS?",
                options: [
                    "To control the visibility of an element",
                    "To change the background color of an element",
                    "To adjust the spacing between elements",
                    "To define how an element is displayed"
                ],
                correctOption: "To define how an element is displayed",
                answerDes: "The 'display' property in CSS is used to define how an element should be displayed, such as inline, block, or none. It affects the layout and rendering of the element."
            },
            {
                questionText: "Which CSS selector targets elements with a specific class?",
                options: [
                    "#",
                    ".",
                    ":",
                    "*"
                ],
                correctOption: ".",
                answerDes: "The CSS selector '.' (dot) is used to target elements with a specific class. It allows you to apply styles to all elements that have the specified class name."
            }
        ],
        times: [1, 5, 15, 25, 35],

    },
    {
        heading: "Josie",
        id: 3,
        url: "/img/Josie.mp4",
        Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        questions: [{
                questionText: "What does HTML stand for?",
                options: [
                    "Hypertext Markup Language",
                    "Hyperlink and Text Markup Language",
                    "Home Tool Markup Language",
                    "HighTech Markup Language"
                ],
                correctOption: "Hypertext Markup Language",
                answerDes: "HTML stands for Hypertext Markup Language. It is the standard language used to create and design web pages, defining their structure, content, and presentation."
            },
            {
                questionText: "Which element is used to create a hyperlink?",
                options: [
                    "<link>",
                    "<a>",
                    "<href>",
                    "<hyper>"
                ],
                correctOption: "<a>",
                answerDes: "The <a> element is used to create hyperlinks in HTML. It defines a hyperlink that links to another web page or resource."
            },
            {
                questionText: "What is the correct HTML tag for inserting a line break?",
                options: [
                    "<br>",
                    "<lb>",
                    "<break>",
                    "<newline>"
                ],
                correctOption: "<br>",
                answerDes: "The correct HTML tag for inserting a line break is the <br> tag. It produces a line break, allowing you to create new lines within your content."
            },
            {
                questionText: "What is the purpose of the <head> element in HTML?",
                options: [
                    "It contains the visible content of the page",
                    "It defines the structure of the document",
                    "It contains metadata about the document",
                    "It is used for styling the page"
                ],
                correctOption: "It contains metadata about the document",
                answerDes: "The <head> element in HTML is used to contain metadata about the document, such as its title, character encoding, and linked stylesheets. It does not contain visible content."
            }
        ],
        times: [1, 5, 15, 25, 35],

    },
    {
        heading: "Nina",
        id: 4,
        url: "/img/Nina.mp4",
        Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
        questions: [{
                questionText: "What does HTML stand for?",
                options: [
                    "Hypertext Markup Language",
                    "Hyperlink and Text Markup Language",
                    "Home Tool Markup Language",
                    "HighTech Markup Language"
                ],
                correctOption: "Hypertext Markup Language",
                answerDes: "HTML stands for Hypertext Markup Language. It is the standard language used to create and design web pages, defining their structure, content, and presentation."
            },
            {
                questionText: "Which element is used to create a hyperlink?",
                options: [
                    "<link>",
                    "<a>",
                    "<href>",
                    "<hyper>"
                ],
                correctOption: "<a>",
                answerDes: "The <a> element is used to create hyperlinks in HTML. It defines a hyperlink that links to another web page or resource."
            },
            {
                questionText: "What is the correct HTML tag for inserting a line break?",
                options: [
                    "<br>",
                    "<lb>",
                    "<break>",
                    "<newline>"
                ],
                correctOption: "<br>",
                answerDes: "The correct HTML tag for inserting a line break is the <br> tag. It produces a line break, allowing you to create new lines within your content."
            },
            {
                questionText: "What is the purpose of the <head> element in HTML?",
                options: [
                    "It contains the visible content of the page",
                    "It defines the structure of the document",
                    "It contains metadata about the document",
                    "It is used for styling the page"
                ],
                correctOption: "It contains metadata about the document",
                answerDes: "The <head> element in HTML is used to contain metadata about the document, such as its title, character encoding, and linked stylesheets. It does not contain visible content."
            }
        ],
        times: [1, 5, 15, 25, 35],

    },
    // Add more videos here
];

export default data;