import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

function HeroMobile() {

    const handleLanguage = (e) => {
		console.log(e.target.value);
		if (e.target.value === "English") {
			window.location.href = "/english";
		} else if (e.target.value === "Bahasa") {
			window.location.href = "/bahasa";
		}
        else if (e.target.value === "Tagalog") {
			window.location.href = "/tagalog";
		}
        // else if (e.target.value === "Malay") {
		// 	window.location.href = "/malay";
		// } else if (e.target.value === "Filipino") {
		// 	window.location.href = "/filipino";
		// }
	};
    return (
        <>
        <div
            className="hero-image"
            style={{
                backgroundImage: `url('https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/images/IMG_2993.avif')`,
                display:"block",
                width: "100%",
                height: "80vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
                zIndex: "1",
                
            }}
        >
            <div
                style={{
                    backgroundColor:"rgba(0,0,0,0.5)",
                    width: "100%",
                    height: "80vh",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                    zIndex: "2",
                }}
            >
            <div
                className="hero-text"
                style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "65%",
                    left: "35%",
                    transform: "translate(-50%, -50%)",
                    // color: "white",
                    textAlign: "left",
                    fontFamily: "monospace",
                    fontWeight: "bold",
                    fontSize: "24px",
                    // color: "rgb(3, 4, 94)",
                    color: "white",
                    opacity: "1",
                }}
            >
                <p>
                    Migrant Worker <br />
                    Financial Literacy <br />Challenge
                </p>
                {/* <a href="/challengesM" style={{ textDecoration: "none" }}> */}
                <button
                    style={{
                        backgroundColor: "rgba(174, 0, 87, 1)",
                        color: "white",
                        padding: "10px 10px 10px 10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        textDecoration: "none",
                        fontSize: "20px",
                        cursor: "pointer",
                        borderRadius: "12px",
                        borderRadius: "25px",
                        border: "none",
                        fontFamily: "sans-serif",
                        fontWeight: "bold",
                        minWidth: "240px",
                        marginTop: "20px",
                    }}
                >
                    {/* Choose a language */}
                    <select style={{scale:"1.3", backgroundColor: "rgba(174, 0, 87, 0.9)", border: "none", color: "white"}} onChange={(e) => handleLanguage(e)}>
                        <option value="null" >Choose a language</option>
						<option value="English">English</option>
						<option value="Bahasa">Bahasa</option>
                        <option value="Tagalog">Tagalog</option>
						{/* <option value="Malay">Malay</option>
						<option value="Filipino">Filipino</option> */}
					</select>
                    {/* <BsArrowRight
                        style={{
                            marginLeft: "10px",
                            fontSize: "15px",
                            position: "relative",
                        }}
                    /> */}
                </button>
                {/* </a> */}
            </div>
            </div>
        </div>
        </>
    );
}

export default HeroMobile;
