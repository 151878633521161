import React, { useEffect, useState } from "react";
import data from "./data";
import Video from "./Video";
import "./VideoPage.css";
import Navbar from "./Navbar";
import NavbarMobile from "./NavbarMobile";



const VideoPage = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(1);



  return (
    <div>
      {
        // !isFullScreen ? (<NavbarMobile className="navbar" />):""
        !isFullScreen ? (<Navbar />):""
      }


      
      <div className="app-container">
        <div className="selected-video">
          {selectedVideo !== null && (
            <Video className="playing-video" {...data.find((video) => video.id === selectedVideo)} 
            isFullScreen={isFullScreen}
            setIsFullScreen={setIsFullScreen}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}/>

          )}
        </div>
        

      </div>
    </div>
  );
}

export default VideoPage;
