import React from 'react'
import "../footerMobile.css"

const FooterMobile = () => {
    return (
        <div className='footerm'>
            
            <div className='footer-itemsm'>
                {/* <a href='https://www.empoweru.io/about' className='foot-itemm'>About</a> */}
                <a href='https://www.empoweru.io/faqs' className='foot-itemm'>FAQs</a>
                <a href='https://www.empoweru.io/privacy' className='foot-itemm'>Privacy</a>
                <a href='https://www.empoweru.io/terms-of-use' className='foot-itemm'>Terms of Use</a>
                {/* <a href='https://www.empoweru.io/contact' className='foot-itemm'>Get Involved</a> */}
            </div>

            <div className='foot-sharem'>

                <a href='https://www.facebook.com/EmpowerUHK/'>
                    <img  loading='lazy' alt='images' className='footer-icom' src="https://static.wixstatic.com/media/aebe5b6fd55f471a936c72ff2c8289d7.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/aebe5b6fd55f471a936c72ff2c8289d7.png"></img>
                </a>


                <a href='https://www.instagram.com/empoweruhk/'>
                    <img  loading='lazy' alt='images' className='footer-icom' src="https://static.wixstatic.com/media/40898a93cfff4578b1779073137eb1b4.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/40898a93cfff4578b1779073137eb1b4.png"></img>
                </a>

                <a href='https://www.linkedin.com/company/empoweruhk?originalSubdomain=hk'>
                    <img  loading='lazy' alt='images' className='footer-icom' src="https://static.wixstatic.com/media/fcfebdfae12a44f993a94aeed8e2e66b.png/v1/fill/w_53,h_53,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/fcfebdfae12a44f993a94aeed8e2e66b.png"></img>
                </a>

            </div>

            {/* <div className='foot-policym'>
                <p className='foot-policym'>© Migrasia Global Solutions Limited 2022</p>

            </div> */}


        </div>
    )
}

export default FooterMobile