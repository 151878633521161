import { constantsBahsapages } from "../comp/data/bashasa";
import { constantsEnglish } from "../comp/data/english";
import { constantsTagalog } from "../comp/data/tagalog";

const getData=(lang)=>{
    if (lang == "bahasa") {
        return(constantsBahsapages);
      } else if (lang == "english") {
    return(constantsEnglish);
      }
      else if (lang == "tagalog") {
        return(constantsTagalog);
          }
      
}
export default getData