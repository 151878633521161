let pages = {
  home: {
    title: "Migrant Worker Financial Literacy Challenge",
    button: "Magsimula",
    route: "tagalog",
    back:"Bumalik",
    container: {
      title: "Ang Mga Videos",

      card1: {
        title: "Excessive Interest",
      },
      card2: {
        title: "Signing for Loans",
      },
      card3: {
        title: "Love Scams",
      },
    },
    thanks : {
      title: "Salamat sa aming mga Voice Artist!",
      names : ["Maria Cristina Delfin Arcilla",
        "Ruth Judea Puentespina",
        "Jealdrin Cortez Madrid"
      ]
    }
  },
  video:{
    home:"home",
    more:"More Challenges",
    continue:"Continue",
    tips:"Mga Tips",
    good:"Green Flag",
    red:"Red Flag",
    challenge:"challenge Completed", 

    play:"Play",
    back:"Back",
    next:"Next"
}
};
const data = [
  {
    heading: "Excessive Interest",
    id: 1,
    url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Farah Tagalog 480p FINAL.mp4",
    subs: "/img/tagalong/Farah.vtt",
    Des: "V2-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
    continuable: {
      cando: true,
    },
    questions: [
      {
        questionText:
          "HANAPIN ANG RED FLAG ACTIVITY <br/><br/>Siguraduhing legal at kagalang-galang ang mga nagpapahiram ng pera! Ang mga masamang nagpapahiram ay kadalasang nagbibigay ng labis na mga rate ng interes, na nagpapahirap sa iyo na bayaran ang utang. Tingnan ang ilang sample na kasunduan sa pautang at hanapin ang mali dito!",
        continuable: {
          cando: true,
        },
        correctOption: "",
        answerDes:
          "CSS stands for Cascading Style Sheets. It is a stylesheet language used to describe the presentation of web pages, including elements like colors, fonts, and layout.",
      },
      {
        questionText: "<img  loading='lazy' alt='images' src='/img/activity1.png' width='300' height='400'>",
        options: ["Good", "Bad"],
        correctOption: "Bad",
        answerDesCorrect:
          "Higit sa 48% ang rate ng interes ay napakataas, at mula 2023, ilegal sa Hong Kong. Ang nagpapahiram ng pera ay sumulat ng halaga na mas mababa lamang sa 48% na rate. Sobrang kahina-hinala yan! I-click ang magpatuloy upang makita ang susunod na kasunduan sa pautang.",
        answerDesWrong:
          "Higit sa 48% ang rate ng interes ay napakataas, at mula 2023, ilegal sa Hong Kong. Ang nagpapahiram ng pera ay sumulat ng halaga na mas mababa lamang sa 48% na rate. Sobrang kahina-hinala yan! I-click ang magpatuloy upang makita ang susunod na kasunduan sa pautang.",
      },
      {
        questionText: "<img  loading='lazy' alt='images' src='/img/acitivity2.png' width='300 height='400'>",
        options: ["Good", "Bad"],
        correctOption: "Bad",
        answerDesCorrect:
          " Ay! Ang mga rate ng interes na 48% pataas ay ilegal sa Hong Kong! Maging maingat sa mga hindi etikal na nagpapahiram ng pera! I-click ang magpatuloy upang makita ang susunod na kasunduan sa pautang.",
        answerDesWrong:
          " Ay! Ang mga rate ng interes na 48% pataas ay ilegal sa Hong Kong! Maging maingat sa mga hindi etikal na nagpapahiram ng pera! I-click ang magpatuloy upang makita ang susunod na kasunduan sa pautang.",
      },
      {
        questionText: "<img  loading='lazy' alt='images' src='/img/activity3.png' width='300' height='400'>",
        options: ["Good", "Bad"],
        newhead: {
          cando: true,
          heading: "Be Careful!",
        },
        correctOption: "Good",
        answerDesCorrect:
          " Mukhang magandang kasunduan ang loan na ito! Siguraduhing gamitin ang iyong pinakamahusay na paghuhusga at pumili ng pautang na kayang bayaran. I-click ang magpatuloy upang makita kung ano ang susunod na mangyayari.",
        answerDesWrong:
          " Mukhang magandang kasunduan ang loan na ito! Siguraduhing gamitin ang iyong pinakamahusay na paghuhusga at pumili ng pautang na kayang bayaran. I-click ang magpatuloy upang makita kung ano ang susunod na mangyayari.",
      },
      {
        questionText: "Paano dapat sasagot si Farah?",
        options: [
          '"Wow! Parang sulit na sulit!"',
          '"Um…Hindi ako sigurado, binabayaran ko na itong loan, ayaw kong kumuha ng isa pa…"',
        ],
        correctOption:
          '"Um…Hindi ako sigurado, binabayaran ko na itong loan, ayaw kong kumuha ng isa pa…"',

        answerDesCorrect:
          "Mga negosyo ang mga nagpapahiram ng pera. Kung alokin ka na muling ayusin ang 'yong utang, (o muli kang papautangin) dapat kang mag-ingat — lalo na kung nag-aalok sila ng mga libreng produkto o pinipilit kang sumang-ayon sa isang mas malaking pautang",

        answerDesWrong:
          "Mga negosyo ang mga nagpapahiram ng pera. Kung alokin ka na muling ayusin ang 'yong utang, (o muli kang papautangin) dapat kang mag-ingat — lalo na kung nag-aalok sila ng mga libreng produkto o pinipilit kang sumang-ayon sa isang mas malaking pautang",
      },
    ],
    tips: "Kung magpasya kang kumuha ng pautang, tiyaking pumili ng mga mapagkakatiwalaan at mapagkakatiwalaang kumpanya ng pagpapautang ng pera. Dapat nilang gabayan ka sa lahat ng mga detalye ng utang. Kung hindi ka komportable o pressured, maaari kang lumayo!<br/><br/>Mula Enero 2023 sa Hong Kong, ang taunang mga rate ng interes na higit sa 48% ay itinuturing na labag sa batas, at ang ahensya ay posibleng nagsasagawa ng ilegal na pag-uugali.<br/><br/>Tandaan: Siguraduhing itago ang lahat ng resibo, larawan, at dokumentong nauugnay sa ahensya o nagpapahiram ng pera. Huwag itapon ang anumang bagay, maaaring makatulong ito sa iyo sa hinaharap!<br/><br/>Kung ikaw ay hina-harass o nasa panganib, mangyaring makipag-ugnayan sa iyong lokal na pulisya",
    times: [79, 79, 79, 79, 121],
  },
  {
    heading: "Signing for Loans",
    id: 2,
    url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Josie TAGALOG Final. 480 version.mp4",
    subs: "/img/tagalong/Josie.vtt",
    Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
    questions: [
      {
        questionText: "Paano dapat sasagot si Josie?",
        options: [
          '"Mapagkakatiwalaan ba ang taong yan? Sure ka ba na ok itong paraan na to?"',
          '"Oo! Kumuha ka ng loan."',
        ],
        correctOption:
          '"Mapagkakatiwalaan ba ang taong yan? Sure ka ba na ok itong paraan na to?"',
        answerDesCorrect:
          "Kailangang magingat at maghanap ng mga kagalang-galang at legal na nagpapahiram ng pera kung kukuha ka ng pautang. Tiyaking kaya mong bayaran ang utang. I-click ang continue para makita kung ano ang mangyayari kung pumayag si Josie.",
        answerDesWrong:
          "Kailangang magingat at maghanap ng mga kagalang-galang at legal na nagpapahiram ng pera kung kukuha ka ng pautang. Tiyaking kaya mong bayaran ang utang. I-click ang continue para makita kung ano ang mangyayari kung pumayag si Josie.",
      },
      {
        questionText: "Paano dapat sasagot si Josie?",
        options: [
          '"Ahh hindi ko alam. Sa totoo lang, hindi ako komportable na ilagay ang pangalan ko. Siguro maghanap tayo ng ibang paraan para makakuha ng pera."',
          '"Sige! Ilagay mo pangalan ko bilang reference."',
        ],
        correctOption:
          '"Ahh hindi ko alam. Sa totoo lang, hindi ako komportable na ilagay ang pangalan ko. Siguro maghanap tayo ng ibang paraan para makakuha ng pera."',
        answerDesCorrect:
          "Ang paglalagay ng iyong pangalan bilang reference, guarantor, o co-borrower ay lubhang mapanganib! Kung hindi makabayad ang iyong kaibigan, maaaring ikaw mismo ang responsable sa pagbabayad ng utang. Maaaring ilagay ka nito sa isang mapanganib na sitwasyon. Kung sasabihin mong oo sa pagiging isang reference, maaaring umu-oo sa buong utang! I-click ang continue upang makita kung ano ang mangyayari kung pumayag si Josie na maging isang reference…",
        answerDesWrong:
          " Ang paglalagay ng iyong pangalan bilang reference, guarantor, o co-borrower ay lubhang mapanganib! Kung hindi makabayad ang iyong kaibigan, maaaring ikaw mismo ang responsable sa pagbabayad ng utang. Maaaring ilagay ka nito sa isang mapanganib na sitwasyon. Kung sasabihin mong oo sa pagiging isang reference, maaaring umu-oo sa buong utang! I-click ang continue upang makita kung ano ang mangyayari kung pumayag si Josie na maging isang reference…",
      },
    ],
    tips: "Iwasang ilagay ang iyong sarili bilang reference, co-borrower, o guarantor sa mga aplikasyon ng pautang ng ibang tao. Bilang isang co-borrower o guarantor, ayon sa batas, ikaw ay mananagot kung ang pangunahing tao ay umalis; bilang isang reference, pwede kang gawing responsable o hindi, ngunit maaaring mangolekta sa iyo ang mga masasamang nagpapautang. Kung magpasya kang kumuha ng pautang, tiyaking pumili ng mga mapagkakatiwalaan at mapagkakatiwalaang kumpanya ng money lending. Kung humingi sila sa iyo ng reference, maaaring hindi sila mapagkatiwalaan. Dapat nilang gabayan ka sa lahat ng mga detalye ng utang, at tiyaking binabasa at nauunawaan mo ang bawat dokumentong pipirmahan mo. Kung hindi ka komportable o pinepressure ka, maaari kang umayaw! Kung ikaw ay hina-harass o nasa panganib, mangyaring makipag-ugnayan sa iyong lokal na pulisya",
       times: [88, 115],
  },
  {
    heading: "Love Scams",
    id: 3,
    url: "https://empoweru-ebdhchc4a3gzesgu.z02.azurefd.net/empoweruvids/empoweruvids/videos/IFEC Nina Tagalog Final. 480 version.mp4",
    subs: "/img/tagalong/Nina.vtt",
    Des: "V1-Welcome to the Smart Migration Challenge, an innovative initiative aimed at addressing the major challenges faced by migrating workers and students in today's globalized world. This challenge is designed to foster creative solutions that leverage technology and innovative thinking to empower migrants during their transition to a new country.",
    questions: [
      {
        questionText: "Paano dapat tumugon si Nina?",
        options: [
          '"Hmmm... hindi pa tayo talaga nagkakasama sa personal. Hindi ako sigurado..."',
          '"Excited na akong makita ka!"',
        ],
        correctOption:
          '"Hmmm... hindi pa tayo talaga nagkakasama sa personal. Hindi ako sigurado..."',
        answerDesCorrect:
          "Babala! Maaaring hindi gaanong totoo ang mga tao sa online! Maaaring gamitin ng mga manloloko ng tinatawag na AI at Deep Fake technology upang baguhin ang kanilang boses at imahe. Maging maingat kapag nakikipagkilala sa mga bagong tao sa online at personal!<br/>Maaaring magbigay ng maraming dahilan ang mga manloloko upang hindi magkaroon ng voice call or video call at patuloy na iwasan ang pagkikita sa personal. Maaari rin nilang pangakuan ng mga regalo upang magkaroon ng tiwala sa iyo. Kung hindi mo pa kilala ang taong ito, mabuting maging mapaghinala<br/>Magpatuloy sa pagbabasa upang makita kung saan patutungo ang kuwento.",
        answerDesWrong:
          "Babala! Maaaring hindi gaanong totoo ang mga tao sa online! Maaaring gamitin ng mga manloloko ng tinatawag na AI at Deep Fake technology upang baguhin ang kanilang boses at imahe. Maging maingat kapag nakikipagkilala sa mga bagong tao sa online at personal!<br/>Maaaring magbigay ng maraming dahilan ang mga manloloko upang hindi magkaroon ng voice call or video call at patuloy na iwasan ang pagkikita sa personal. Maaari rin nilang pangakuan ng mga regalo upang magkaroon ng tiwala sa iyo. Kung hindi mo pa kilala ang taong ito, mabuting maging mapaghinala<br/>Magpatuloy sa pagbabasa upang makita kung saan patutungo ang kuwento.",
      },
      {
        questionText: "Paano dapat tumugon si Nina?",
        options: [
          '"Hindi ko alam. Parang hindi ligtas"',
          '"Huwag kang mag-alala. Magpapadala ako ng pera"',
        ],
        correctOption: '"Hindi ko alam. Parang hindi ligtas"',
        answerDesCorrect:
          "  Maraming iba't ibang diskarte ang ginagamit ng mga manloloko para kumbinsihin kang magpadala ng pera o gumawa ng mga ilegal na gawain. Mas mabuti na maging maingat upang maiwasang maging biktima",
        answerDesWrong:
          "  Maraming iba't ibang diskarte ang ginagamit ng mga manloloko para kumbinsihin kang magpadala ng pera o gumawa ng mga ilegal na gawain. Mas mabuti na maging maingat upang maiwasang maging biktima",
      },
    ],
    tips: "Ang mga manloloko sa pag-ibig ay maaaring manloko ka sa pamamagitan ng pagpapanggap na mayaman at makapangyarihan. Mabilis nilang binubuo ng isang relasyon. Gumagamit sila ng iba't ibang diskarte at eksperto sila sa pagkuha ng tiwala sa pamamagitan ng pagpapanggap na nagmamalasakit sa iyo.<br/><br/>Bukod sa mga dating app, maaari ring subukang magpadala ng mga friend request ang mga manloloko sa ibang social media! <br/><br/>Maaaring gamitin ng mga manloloko ang AI at Deep Fake technology upang baguhin ang kanilang boses at imahe! Maging mapagmasid sa mga bagong tao na nakikilala mo online at personal.<br/><br/>Kung ikaw ay nasa Hong Kong at may mga pagdududa o naghihinala sa isang scam, tumawag sa 18222 upang maabot ang Anti-Scam Helpline ng Anti-Deception Coordination Center (ADCC) ng Hong Kong Police Force. Kung ikaw ay inaabuso o nasa panganib, makipag-ugnay sa iyong lokal na pulisya.    ",
    times: [118, 169],
  },
  // Add more videos here
];
module.exports.constantsTagalog = { pages, data };
